import React, {useState, useEffect, useRef} from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Mask = (props) => {
  const maskRef = useRef(null);
  const [viewport, setViewport] = useState();

  useEffect(() => {

    setViewport(window.innerWidth);
    
    window.addEventListener('resize', () => {
      setTimeout(() => {
        setViewport(window.innerWidth);
      }, 500);
    });

    // console.log(viewport);

    if(viewport >= "1440") {
      gsap.to('.stellco_mask', {
        scrub: true,
        scrollTrigger: {
          trigger: '.stellco_mask[data-mask="about"]',
          start: "center center",
          endTrigger: ".stellco_beer",
          end: "center center",
          pin: true,
          toggleActions: "restart pause reverse pause",
          // markers: true,
        },
      });
      
      gsap.to('.stellco_mask[data-mask="coffee"]', {
        opacity: 1,
        scrub: true,
        scrollTrigger: {
          trigger: '.stellco_drinks',
          start: "top center",
          // endTrigger: ".stellco_beer",
          end: "top bottom",
          // pin: true,
          toggleActions: "restart none reverse none",
          // markers: true,
        },
      });
      
      gsap.to('.stellco_mask[data-mask="beer"]', {
        opacity: 1,
        scrub: true,
        scrollTrigger: {
          trigger: '.stellco_food',
          start: "top center",
          // endTrigger: ".stellco_beer",
          end: "top bottom",
          // pin: true,
          toggleActions: "restart none reverse none",
          // markers: true,
        },
      });
    }
  }, [viewport]);

  return (
    <React.Fragment>
      <div aria-label="Animated image mask of coffee cup, a glass mason jar of iced coffee, and woman holding cup of coffee" role="img" ref={maskRef} className="stellco_mask" data-area="about" data-mask="about">
        <div className="stellco_mask" data-mask="coffee"></div>
        <div className="stellco_mask" data-mask="beer"></div>
      </div>
      <div className="stellco_mask" data-area="coffee"></div>
      <div className="stellco_mask" data-area="beer"></div>
    </React.Fragment>
  );
}

export default Mask;