import React, {useRef, useEffect} from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
 
const Action = (props) => {
  const logoRef = useRef(null);
  const actionRef = useRef(null);

  useEffect(() => {
    gsap.to(actionRef.current, {opacity: 1, duration: 2, delay: 1});
    ScrollTrigger.refresh();
    ScrollTrigger.create({
      trigger: '.stellco_bio',
      start: '-=10',
      onEnter: () => {logoRef.current.setAttribute('data-type', 'black')},
      onLeaveBack: () => {logoRef.current.setAttribute('data-type', 'orange')},
      // markers: true
    });

    ScrollTrigger.refresh();
    ScrollTrigger.create({
      trigger: '.stellco_drinks',
      start: '-=10',
      onEnter: () => {logoRef.current.setAttribute('data-type', 'orange')},
      onLeaveBack: () => {logoRef.current.setAttribute('data-type', 'black')},
      // markers: true
    });
    
    ScrollTrigger.refresh();
    ScrollTrigger.create({
      trigger: '.stellco_footer',
      start: '-=125',
      onEnter: () => {logoRef.current.setAttribute('data-type', 'black')},
      onLeaveBack: () => {logoRef.current.setAttribute('data-type', 'orange')},
      // markers: true
    });
  }, []);

  return(
    <div className="stellco_action" ref={actionRef}>
      <a href='https://stellco.revelup.online/' 
        aria-label="External Link, Order For Pickup"
        role="button"
        tabIndex="0"
        ref={logoRef} 
        className="stellco_action__btn" 
        rel="noreferrer" 
        target="_blank">Order For Pickup</a>
    </div>
  )
}

export default Action;