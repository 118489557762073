const Beans = (props) => {
  return(
    <div id="skiptomain" className="stellco_bio">
      <div className="stellco_bio__content">
        <h2 className="stellco_bio__services">
          Come for the coffee, stay for the food...
          or beer... 
          or wine.. 
          or tea...
        </h2>

        <p aria-label="Established in 2004 by Amy and Robbie Stell, Stell Coffee &amp; Tea Co. 
          or simply Stell's is a cafe and coffee roasterie. Stell's offers a variety 
          of craft beverages such as and not limited to Specialty coffee, 
          Fine loose leaf teas, local draft beer &amp; draft kombucha." role="main" className="stellco_bio__about">
          Established in 2004 by Amy and Robbie Stell, Stell Coffee &amp; Tea Co. 
          or simply Stell's is a cafe and coffee roasterie. Stell's offers a variety 
          of craft beverages such as and not limited to Specialty coffee, 
          Fine loose leaf teas, local draft beer &amp; draft kombucha.   
        </p>
      </div>
    </div>
  )
}

export default Beans;