import { useState, useEffect, useRef } from 'react';
import menuData from '../../js/menu-data.json'

const Menu = (props) => {
  const [menuSelection, setMenuSelection] = useState('coffee');
  const [menuDescription, setMenuDescription] = useState([]);
  const [currentMenuItem, setCurrentMenuItem] = useState(0);
  
  const prevLimit = useRef(null);
  const nextLimit = useRef(null);

  const menuItems = [
    'coffee',
    'bakery',
    'breakfast',
    'lunch',
    'blended',
    'tea',
    'milk',
    'kombucha',
    'beans'
  ];

  const toggleMenuItems = (direction) => {
    if(direction === 'next') {
      if((currentMenuItem + 1) >= 8) {
        setMenuSelection(menuItems[8]);
        prevLimit.current.setAttribute('data-limit', 'false');
        nextLimit.current.setAttribute('data-limit', 'true');
      }else{
        setCurrentMenuItem(currentMenuItem + 1);
        setMenuSelection(menuItems[currentMenuItem + 1]);
        prevLimit.current.setAttribute('data-limit', 'false');
        nextLimit.current.setAttribute('data-limit', 'false');
      }
    }else if(direction === 'prev') {
      if((currentMenuItem - 1) <= 0) {
        setMenuSelection(menuItems[0]);
        nextLimit.current.setAttribute('data-limit', 'false');
        prevLimit.current.setAttribute('data-limit', 'true');
      }else{
        setCurrentMenuItem(currentMenuItem - 1);
        setMenuSelection(menuItems[currentMenuItem - 1]);
        nextLimit.current.setAttribute('data-limit', 'false');
        prevLimit.current.setAttribute('data-limit', 'false');
      }
    }
  };

  useEffect(() => {
    menuData.forEach((data) => {
      if(data.category === menuSelection) {
        setMenuDescription([{
          category: data.category,
          preview: data.preview,
          item: data.item,
          details: data.details,
          price: data.price,
        }]);
      }
    });
  },[menuSelection]);

  return(
    <div className="stellco_menu">
      <div className="stellco_menu__preview" data-menu={menuSelection}>
        <div aria-label="Photos of menu items offered at Stell Co." role="img" className="stellco_menu__paddles">
          <button className="stellco_menu__paddles___left" 
            ref={prevLimit}
            aria-label="Navigate to previous image"
            onClick={() => toggleMenuItems('prev')}>
            <svg className="stellco_menu__paddles___svg" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path xmlns="http://www.w3.org/2000/svg" d="M10.207 4l-4 4 4 4H8.793l-4-4 4-4z"/>
              <path xmlns="http://www.w3.org/2000/svg" fill="none" d="M0 0h16v16H0z"/>
            </svg>
          </button>
          <button className="stellco_menu__paddles___right" 
            ref={nextLimit}
            aria-label="Navigate to next image"
            onClick={() => toggleMenuItems('next')}>
            <svg className="stellco_menu__paddles___svg" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <path d="M5.793 12l4-4-4-4h1.414l4 4-4 4z"/>
              <path fill="none" d="M0 0h16v16H0z"/>
            </svg>
          </button>
        </div>
      </div>
    
      <div className="stellco_menu__choices">
        <div className="stellco_menu__choices_selections">
          {menuDescription.length > 0 && menuDescription.map((menuItem, index) => (
            <div key={index} className="stellco_menu__choices_selection">
              <div className="stellco_menu__choices_selection_name">
                <h2 className="stellco_menu__choices_selection_header">{menuItem.item}</h2>
                <p className="stellco_menu__choices_selection_description">{menuItem.details}</p>
              </div>
            </div>
          ))}

          
          <div className="stellco_menu__choices_items">
            <nav className="stellco_menu__choices_items_list">
              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'coffee'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('coffee');
                }} data-category="coffee">Coffee &amp; Espresso</a>

              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'bakery'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('bakery');
                }} data-category="bakery">Bakery</a>

              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'breakfast'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('breakfast');
                }} data-category="breakfast">Breakfast</a>

              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'lunch'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('lunch');
                }} data-category="lunch">Lunch/Dinner</a>
              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'blended'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('blended');
                }} data-category="blended">Blended</a>

              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'tea'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('tea');}} data-category="tea">Tea</a>

              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'milk'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('milk');
                }} data-category="milk">Milk</a>

              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'kombucha'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('kombucha');
                }} data-category="kombucha">Kombucha</a>

              <a href="/" 
                className="stellco_menu__choices_items_name" 
                data-active={menuSelection === 'beans'}
                onClick={(evt) => {
                  evt.preventDefault(); 
                  setMenuSelection('beans');
                }} data-category="beans">Beans</a>
            </nav>
          </div>
        </div>

        <div className="stellco_menu__choices_footer">Eat &amp; Drink</div>
      </div>
    </div>
  )
}

export default Menu;