import PropTypes from 'prop-types';

const CoffeeTea = (props) => {
  return (
    <span className="stellco_roasted__bean" data-depth={props.depth} data-type={props.type}>
      <img  className="stellco_roasted__bean_img" src={props.src} alt={props.alt} />
    </span>
  );
}

CoffeeTea.defaultProps = {
  alt: 'Stell Co. | Roasted Bean'
};

CoffeeTea.propTypes = {
  src: PropTypes.string,
  depth: PropTypes.string,
  type: PropTypes.string,
  alt: PropTypes.string,
}

export default CoffeeTea;