const Coffee = (props) => {
  return(
    <div className="stellco_services">
      <div className="stellco_services__content">
        <h2 className="stellco_services__header">Small Batch Baking</h2>

        <p className="stellco_services__description">
          We bake a variety of cookies, breads and seasonal 
          offerings made from scratch the old fashioned way. 
          Our baking style is known to be savory sweet. 
          Giving you a perfect balance of salty &amp; sweet. 
          The way our grand folks did it.</p>
      </div>
    </div>
  )
}

export default Coffee;