const Coffee = (props) => {
  return(
    <div className="stellco_beer">
      <div className="stellco_beer__content">
        <h2 className="stellco_beer__heading">Local Craft Beer</h2>

        <p className="stellco_beer__description">
          We offer multiple local, micro brews on tap as well 
          as a few Kombucha selections. Yeah... we have kombucha on tap.
          Our taps are always changing, be sure to keep up with us on social 
          media to see whats new.
        </p>
      </div>
    </div>
  )
}

export default Coffee;