import React, {useEffect, useRef} from 'react';

import CoffeeTea from '../CoffeeTea/CoffeeTea';

import beanCracked1 from '../../images/stell-coffee-bean-1.png';
import beanCracked2 from '../../images/stell-coffee-bean-2.png';
import beanCracked3 from '../../images/stell-coffee-bean-3.png';
import beanCracked4 from '../../images/stell-coffee-bean-4.png';
import beanCracked5 from '../../images/stell-coffee-bean-5.png';
import beanCracked6 from '../../images/stell-coffee-bean-6.png';

import teaLeaf1 from '../../images/stell-tea-leaf-1.png';
import teaLeaf2 from '../../images/stell-tea-leaf-2.png';

import {parallax} from "../Animate/animate.js";

const Slogan = (props) => {
  let beans = useRef(null);

  useEffect(() => {
    parallax(beans);
  }, []);

  return(
    <div className="stellco_slogan">
      <div className="beans" ref={elem => beans = elem}>
        <CoffeeTea src={beanCracked1} depth="1" />        
        <CoffeeTea src={beanCracked2} depth="2" />
        <CoffeeTea src={beanCracked3} depth="3" />        
        <CoffeeTea src={beanCracked4} depth="4" />
        <CoffeeTea src={beanCracked5} depth="5" />        
        <CoffeeTea src={beanCracked6} depth="6" />
        <CoffeeTea src={beanCracked4} depth="7" />
        <CoffeeTea src={teaLeaf1} depth="8" alt="Stell Co. | Tea Leaf" />
        <CoffeeTea src={teaLeaf2} depth="9" alt="Stell Co. | Tea Leaf" />
      </div>

      <h1 className="stellco_slogan__wrapper">
        <header aria-label="Redlands" className="stellco_slogan__redlands">Redlands</header>
        <header aria-label="Coffee"  className="stellco_slogan__coffee">Coffee</header>
        <header aria-label="Roasters" className="stellco_slogan__roasters">Roasters</header>
      </h1>

      <p className="stellco_order_text">
        <span className="stellco_order_text_pickup">
          Order For Pickup at
        </span>{" "}
        <span className="stellco_order_text_destination">stellco.revelup.online</span>
      </p>
    </div>
  )
}

export default Slogan;