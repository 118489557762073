const Coffee = (props) => {
  return(
    <div className="stellco_food">
      <div className="stellco_food__content">
        <h2 className="stellco_food__header">
          Fresh Food &amp; Bakery
        </h2>

        <p className="stellco_food__description">
          Fresh Sandwiches, Soups &amp; Salads. Made to order. 
          We slice our produce fresh all day. 
          We offer the highest quality meats and cheeses.
          Our bread is made locally with minimal ingredients. Our soups are made from scratch 
          in house and our salads are a meal! We feed you the way we like to eat. Whether you 
          dine in our take out We want you to be full and happy! We accommodate meat eaters as 
          well as vegan diets. 
        </p>
      </div>
    </div>
  )
}

export default Coffee;