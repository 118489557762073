import React from "react";

const Footer = () => {
  return (
    <footer className="stellco_footer">
      <div className="stellco_footer__content">
        <div className="stellco_footer__content___wrapper">
          <div className="stellco_footer__header">
            <h3 className="stellco_footer__header_text">Come in for a cup</h3>
          </div>
          <div className="stellco_footer__information">
            <div className="stellco_footer__information___visit">
              <p className="stellco_footer__information___visit_label">Visit</p>
              <p className="stellco_footer__information___visit_text">Come see us at our beautiful Redlands locations:</p>
            </div>
            
            <div className="stellco_footer__information___location_wrapper" data-locations>
              <a aria-label="External link to maps, Stell Co. Redlands location, 1580 Barton Rd. Suite A, Redlands, Ca. 92373 USA" href="https://www.google.com/maps/place/1580+Barton+Rd,+Redlands,+CA+92373/@34.0475582,-117.2104482,17z/data=!3m1!4b1!4m5!3m4!1s0x80dcaa3a050ce0db:0xfbac17c7d6f0a880!8m2!3d34.0475538!4d-117.2082595" target="_blank" rel="noreferrer" className="stellco_footer__information___location">
                <p className="stellco_footer__information___location_text">1580 Barton Rd. Suite A</p>
                <p className="stellco_footer__information___location_text">Redlands, Ca</p>
                <p className="stellco_footer__information___location_text">92373 United States</p>
              </a>
              
              <a aria-label="External link to maps, Stell Co. Redlands location, 1453 Ford St. Unit 103, Redlands, Ca. 92373 USA" href="https://www.google.com/maps/place/1453+Ford+St,+Redlands,+CA+92373/@34.0390612,-117.1582153,17z/data=!4m5!3m4!1s0x80db560ea70963e3:0x52a1bfd02c28ce22!8m2!3d34.0390568!4d-117.1560266" target="_blank" rel="noreferrer" className="stellco_footer__information___location">
                <p className="stellco_footer__information___location_text">1453 Ford St. Unit 103</p>
                <p className="stellco_footer__information___location_text">Redlands, Ca</p>
                <p className="stellco_footer__information___location_text">92373 United States</p>
              </a>
            </div>
            
            <div className="stellco_footer__information___location_wrapper" data-social>
              <div className="stellco_footer__information___location">
                <p aria-label="Contact Stell Co." role="heading" aria-level="3" className="stellco_footer__information___location_heading">Contact</p>
                <a aria-label="Email Stell Co. at robbie@stell.co" href="mailto:robbie@stell.co" className="stellco_footer__information___location_text email">robbie@stell.co</a>
                <a aria-label="Call Stell Co. at 909-792-4700" href="tel:909-792-4700" className="stellco_footer__information___location_text">909-792-4700</a>
              </div>
              
              <div className="stellco_footer__information___location">
                <p aria-label="Social Media Channels - Stell Co." role="heading" aria-level="3" className="stellco_footer__information___location_heading">Social</p>
                <div className="stellco_footer__information___location_social">
                  <a aria-label="External Link to the Stell Co. Instagram Social Media Page" href="https://www.instagram.com/stell_coffee_tea_co/?hl=en" rel="noreferrer" target="_blank" className="stellco_footer__information___location_socialIcon">
                    <svg className="stellco_footer__information___location_socialIcon svg" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12,2.16c3.2,0,3.58,0,4.85.07a6.67,6.67,0,0,1,2.23.42,3.61,3.61,0,0,1,1.38.89,3.61,3.61,0,0,1,.89,1.38,6.67,6.67,0,0,1,.42,2.23c.06,1.27.07,1.65.07,4.85s0,3.58-.07,4.85a6.67,6.67,0,0,1-.42,2.23,3.89,3.89,0,0,1-2.27,2.27,6.67,6.67,0,0,1-2.23.42c-1.27.06-1.65.07-4.85.07s-3.58,0-4.85-.07a6.67,6.67,0,0,1-2.23-.42,3.61,3.61,0,0,1-1.38-.89,3.61,3.61,0,0,1-.89-1.38,6.67,6.67,0,0,1-.42-2.23c-.06-1.27-.07-1.65-.07-4.85s0-3.58.07-4.85a6.67,6.67,0,0,1,.42-2.23,3.61,3.61,0,0,1,.89-1.38,3.61,3.61,0,0,1,1.38-.89,6.67,6.67,0,0,1,2.23-.42C8.42,2.17,8.8,2.16,12,2.16M12,0C8.74,0,8.33,0,7.05.07A8.76,8.76,0,0,0,4.14.63,6.07,6.07,0,0,0,.63,4.14,8.76,8.76,0,0,0,.07,7.05C0,8.33,0,8.74,0,12S0,15.67.07,17a8.76,8.76,0,0,0,.56,2.91,6.07,6.07,0,0,0,3.51,3.51,8.76,8.76,0,0,0,2.91.56C8.33,24,8.74,24,12,24s3.67,0,4.95-.07a8.76,8.76,0,0,0,2.91-.56,6.07,6.07,0,0,0,3.51-3.51A8.76,8.76,0,0,0,23.93,17C24,15.67,24,15.26,24,12s0-3.67-.07-4.95a8.76,8.76,0,0,0-.56-2.91A6.07,6.07,0,0,0,19.86.63,8.76,8.76,0,0,0,17,.07C15.67,0,15.26,0,12,0Z"/><path d="M12,5.84A6.16,6.16,0,1,0,18.16,12,6.16,6.16,0,0,0,12,5.84ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Z"/><circle cx="18.41" cy="5.59" r="1.44"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="stellco_footer__copyright">
        <div className="stellco_footer__copyright___text">&copy; 2021, Stell Coffee &amp; Tea</div>
        <div className="stellco_footer__copyright___studiosupermassive">
          <p className="stellco_footer__copyright___studiosupermassive_designBy">Designed by:</p>
          <a aria-label="External Link, Designed by: Studio Supermassive" href="https://www.studiosupermassive.com" rel="noreferrer" target="_blank" className="stellco_footer__copyright___studiosupermassive_logo">https://www.studiosupermassive.com</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;