const Beverages = ({children}, {props}) => {
  return(
    <div className="stellco_drinks">
      <div className="stellco_drinks__content">
        <h2 className="stellco_drinks__heading">Our Coffee &amp; Tea</h2>

        <p className="stellco_drinks__description">
          Espresso is the method of "pulling" clean hot water
          through finely ground, compact coffee.
          Resulting in a rich &amp; complex extraction with
          luxurious viscosity of warm honey. Served as a double shot
          or as the base for traditional expresso beverages.
        </p>
      </div>
    </div>
  )
}

export default Beverages;