import { useEffect, useRef } from 'react';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.refresh();

const Preloader = (props) => {
  const preloader = useRef(null);
  const progress = useRef(null);
  
  useEffect(() => {
    gsap.to(preloader.current, {
      delay: 6,
      top: '90px',
      scale: .30,
      duration: 1
    });
  }, []);

  return (
    <div aria-label="Preloader, animation of cup filling up with coffee" role="img" ref={preloader} className="stellco_preloader">
      <svg id="Layer_1" role="presentation" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2787 2034.29">
        <g className="st0">
          <path className="st1" d="M2644.4,972.9v-6.5c-0.4-290.5,0.4-813.6,0.5-915.5L2645,0H1596.2l-0.6,192.5h-393V0h-876
            C200.4,1.5,123.7,60.7,81.1,110.6C28.3,172.7,0.3,251.6,0,338.7c-0.1,64.6,15,131,44.9,197.4c59.6,133.1,118.6,260.2,180.3,388.7
            l4.6,9.5l-10.2,2.4c-63.3,14.7-144.9,33.7-150,34.9l-37.4,8.7l4.3,508.9l162.2-49.4l2.6,7.7c5,14.7,10.6,31.1,16.6,48.3l8-2.8
            l-7.4,4.7c20.5,59.3,43.7,126.5,55.4,169.8l10.1,37.6l38.9-2.2c5.6-0.3,11.1-0.5,16.5-0.5c84.2,0,105.3,37.9,143.6,106.8
            c52.8,94.8,125,224.7,413.4,224.7c8.2,0,16.5-0.1,25.3-0.3l943.7,0.6c8.3,0.2,16.4,0.3,24.4,0.3c287.3,0,360.8-131.6,414.5-227.8
            c40.7-72.9,61.9-105.2,136.5-105.2c8,0,16.6,0.4,25.6,1.3l32.9,3.1l128-260.5l159.7,45.4v-481.4L2644.4,972.9z M2770,1468.1
            l-151.7-43.1l-129.1,262.7l-21.2-2c-9.5-0.9-18.7-1.3-27.2-1.3c-83.9,0-109.6,39.1-151.4,113.9c-51.6,92.4-122.2,219-399.6,219
            c-7.8,0-15.8-0.1-24.2-0.3l-944.1-0.6c-8.9,0.2-17,0.3-25.1,0.3c-278.3,0-347.8-124.8-398.5-215.9
            c-38.7-69.5-64.3-115.5-158.5-115.5c-5.7,0-11.6,0.2-17.5,0.5l-25.1,1.4l-6.6-24.2c-11.8-43.8-35.1-111.3-55.7-170.9l-0.7-2
            c-5.9-17.2-11.6-33.5-16.6-48.2l-7.9-23.3l-156.2,47.6l-4-472.5l24.1-5.6c5.1-1.2,86.7-20.2,150-34.8l30.9-7.2l-13.8-28.6
            C178.9,789.1,119.9,662.1,60.4,529.1C31.5,464.9,16.9,400.9,17,338.7c0.2-83,26.9-158.1,77.5-217.7C134.4,74.4,207,18.4,326.7,17
            h858.9v192.5h426.9l0.6-192.5h1014.8l-0.1,33.8c-0.2,101.9-1,625-0.5,915.6v19.7l142.6,36.3L2770,1468.1z"/>
          <path className="st2" d="M2605.4,1002.7c-0.7-304.3,0.5-966,0.5-966h-954.5l-0.6,186h-2.3v7.3H1158V36.7H327.2
            C218.6,38,153,88.4,117.1,130.5l-0.2,0.2c-24.3,28.6-42.7,61.7-54.3,97.4c-10.5,32.5-15.8,66.4-15.7,100.5
            c-0.1,58,13.7,118,40.9,178.4c68,152,136.7,299.2,208.4,446.7c1,2,2,4.1,3,6.1c-30.3,6.7-195.6,45.2-217.3,50.2l-2.4,0.5l3.5,407.9
            l146.7-44.7c12.2,44.7,69.4,197.5,90.6,276c294.3-16.8,102.6,344.2,603.2,331.8l948.6,0.6c277.6,6.8,342.8-104.7,396.4-200.7
            c43.1-77.1,78.7-144.2,210.9-131.8l131.5-267.8l137.7,39.2v-382.2C2748.6,1039.1,2613.5,1004.7,2605.4,1002.7z M1782.7,170.6h258.1
            c0,0-1.9,548.1-0.1,808.2c0.1,12.5,2.8,26.2,34.5,30.7c30.4,4.4,60.9,9,90.6,13.7l20.2,3.2l0.8,122.5l-28.3-4.9
            c-91.3-15.7-183.2-29.1-273.2-40c-28-3.4-52-14.6-71.4-33.4c-20.5-19.6-31-42.5-31.2-67.9L1782.7,170.6z M2185,1417.2
            c-9.4,18.3-18.8,36.5-28,54.8c-9.6-2.3-19.2-4.6-28.9-6.8c31.7-67.5,64.5-134.7,98.4-201.5c8.5,2.1,17.1,4.2,25.6,6.4
            c-1.9,74.8-4.9,149.4-8.9,223.8c-9.4-2.6-18.9-5.1-28.4-7.5c0.7-20.5,1.2-40.9,1.8-61.4C2206.1,1422.4,2195.5,1419.7,2185,1417.2
            L2185,1417.2z M1963.3,1380c-4.2-27.9-8.6-55.8-13.3-83.7c-7.1,43.4-14.2,86.9-21.3,130.3c-9.5-1.7-19-3.4-28.6-5.1
            c11.1-72.1,22.2-144.2,33.3-216.2c10.5,1.6,21,3.3,31.5,5c4.9,25.5,9.6,51.1,14.1,76.7c12.7-22.6,25.5-45.1,38.5-67.6
            c10.6,1.9,21.2,3.9,31.7,5.9l-40.8,215c-9.4-1.8-18.9-3.5-28.3-5.2l23.3-129.4C1989.9,1330.3,1976.5,1355.1,1963.3,1380z
            M2084.1,1232c17.8,3.6,35.5,7.3,53.2,11.1c10.3,2.1,19.9,6.8,27.8,13.8c12.9,13.3,16.4,32.2,10.7,56.3c-2.6,11.4-7.8,22-15.3,31
            c-6.5,7.6-12.6,12.2-18.3,13.5c-9.1,2.1-18.5,2.1-27.5,0c-8.2-1.8-16.3-3.5-24.5-5.2l-21,99.8c-9.4-2-18.8-3.9-28.2-5.8
            C2055.4,1375,2069.8,1303.5,2084.1,1232z M1776.2,1320.5c0.5-11,1.2-20.7,2.1-29.2c4.2-37.8,10.9-64.8,20.6-81.1
            c4.3-6.9,10.8-12,19.5-15.3s18.6-4.4,29.6-3c23.5,3,37.9,13.2,43.3,30.2c5.3,18.2,4.9,48.7-1.4,91.5c-1,6.9-2,12.7-3.1,17.5
            c-5,21.2-7.5,31.8-7.5,32.1c-3.9,13.6-8.2,23.8-12.7,30.8c-10.8,14.7-26.3,20.6-46.7,17.9c-20.8-2.7-34-12.3-40-29.3
            C1776.2,1372.9,1775,1352.3,1776.2,1320.5L1776.2,1320.5z M1135.3,389.8c21-17,47.5-25.8,78.9-26h406c29.2,0.4,57.2,10.2,83.3,29
            c33.7,24.3,40.8,52.5,40.9,71.8l1.3,299.5l-308.6-9.4l54.3,194.9c0,0,155.2,5.5,232.8,10.5l22.5,1.4l0.5,116.6l-25.9-1.8
            c-128.1-8.8-258.4-13.3-387.4-13.3c-43.5,0-73.7-24.1-85.1-67.9c-57.9-205.4-102.1-363-143.3-511.1c-2.9-8.2-4.3-16.7-4.3-25.4
            C1101.3,431.6,1113.1,407.8,1135.3,389.8z M1612.9,1559.4c2.6,4,7.1,9.3,13.5,15.8c11.9,12.3,20,23.2,24.2,32.6
            c3.1,7.3,4.7,15.9,4.7,25.8c0,9.8-3.1,18.2-9.2,25c-7,7.7-16.7,11.5-29.1,11.5c-10.2,0-18.4-3.8-24.4-11.5
            c-5.9-7.2-9-16.7-9.2-28.3h21.9c0,5.5,1.3,10,3.9,13.5c2.6,3.6,5.9,5.3,9.8,5.3c9.3,0,13.9-5.7,13.9-17.2
            c0.1-7.5-1.8-14.9-5.3-21.5c-2.9-5.1-7.6-11-14.2-17.8c-19.3-19.3-28.9-37.5-28.9-54.6c0-10,2.6-18,7.8-24
            c6.1-7,15.4-10.5,27.9-10.5c11.1,0,19.7,3.8,25.8,11.5c5.2,6.3,8.1,14.5,8.8,24.6h-20.7c-1-10.4-5.8-15.6-14.6-15.6
            c-9.4,0-14.2,4.8-14.2,14.4C1605.5,1545.4,1608,1552.3,1612.9,1559.4L1612.9,1559.4z M1580.9,1388.9c-9.7-0.5-19.4-0.9-29.1-1.4
            c-3.6-20.1-7.3-40.3-11.1-60.4c-10.7-0.4-21.5-0.8-32.2-1.1c-5.4,19.8-10.7,39.7-15.9,59.5c-9.8-0.2-19.6-0.4-29.5-0.6
            c16.8-72.7,34.6-145.2,53.6-217.7c8.7,0.3,17.4,0.6,26.2,0.9C1556.7,1241.7,1569.3,1315.3,1580.9,1388.9L1580.9,1388.9z
            M1308,1386.7c-9.9,0.3-19.8,0.7-29.7,1.2c-2.9-64.4-5.7-128.8-8.6-193.2c-11.9,0.5-23.8,1.1-35.7,1.8c-0.5-8.5-0.9-16.9-1.4-25.4
            c33.4-1.8,66.9-3.2,100.4-4c0.2,8.5,0.4,16.9,0.6,25.4c-10.8,0.3-21.6,0.6-32.4,1C1303.6,1257.8,1305.8,1322.2,1308,1386.7
            L1308,1386.7z M1024,170.6v193.3h59.4l-0.6,122.2h-59.3c-0.9,154.3-1.7,308.6-2.6,462.9c0,3.5,0.3,9.8,3,12.5s9.7,4.3,18.6,4.3
            c2.3,0,4.7-0.1,7.3-0.3c16.7-1.3,32.8-2.5,52.1-3.8l25.9-1.8l-0.5,115.3l-22.3,1.6c-77.2,5.6-156.5,13.1-235.9,22.2
            c-5.1,0.6-10.3,0.9-15.5,0.9c-22.3,0-42.1-6-58.6-18c-21.9-15.8-33.4-38.3-33.2-65.1l-0.1-846.2L1024,170.6z M921.2,1292.2
            c11.3-1.7,22.6-3.4,33.8-5c1.2,8.4,2.4,16.8,3.6,25.2c-11.2,1.6-22.4,3.2-33.6,4.9c4,26.1,8,52.2,12,78.3c19.8-3,39.6-5.9,59.5-8.5
            c1.1,8.4,2.2,16.8,3.4,25.3c-29.2,3.9-58.3,8.2-87.4,12.9c-11.7-72-23.3-144-35-216c30.1-4.9,60.3-9.3,90.5-13.4l3.4,25.2
            c-19.9,2.7-39.8,5.5-59.7,8.6C914.9,1250.6,918,1271.4,921.2,1292.2z M851.6,1213.7c1.4,8.4,2.8,16.7,4.2,25.1
            c-19.9,3.4-39.7,6.9-59.5,10.6c3.9,20.7,7.8,41.4,11.7,62.1c11.3-2.1,22.5-4.2,33.8-6.2c1.5,8.4,3,16.7,4.5,25.1
            c-11.2,2-22.3,4-33.5,6.1c4.9,25.9,9.8,51.9,14.7,77.9c19.7-3.7,39.5-7.2,59.3-10.6c1.4,8.4,2.8,16.8,4.2,25.1
            c-29.1,4.9-58.1,10.2-87,16c-14.2-71.6-28.3-143.1-42.4-214.7C791.5,1224.4,821.5,1218.9,851.6,1213.7L851.6,1213.7z M742.8,1260
            c-19.8,4.1-39.5,8.3-59.2,12.7c4.6,20.5,9.2,41.1,13.8,61.6c11.2-2.5,22.4-4.9,33.6-7.3c1.8,8.3,3.5,16.6,5.3,24.9
            c-11.1,2.4-22.2,4.8-33.3,7.3c7.6,34.1,15.3,68.1,22.9,102.2c-9.3,2.1-18.7,4.2-28,6.4l-49.8-213c29.8-7,59.7-13.5,89.6-19.7
            C739.4,1243.4,741.1,1251.7,742.8,1260z M213.4,1278.2V1117l18.9-4.4c77.7-18.5,155.8-35.5,232-50.4c16.9-3.3,19.4-10.5,20.4-13.6
            c2-5.8-0.3-15.7-6.5-27.9C384.4,831.9,296.7,646,209.9,452.1c-19.4-42.9-29.2-84.4-29.1-123.3c0.1-44.4,13-81.9,38.1-111.5
            c26-30.5,63-46.2,109.9-46.8h377.2l-0.7,122H448.5c-11,0.3-15.6,4.2-18.6,9.9c-1.1,4.6,0.2,14.5,7.2,29.6
            C523.6,518.9,611.7,700,706.5,885.8c19.6,38.4,29.5,75.9,29.3,111.3c-0.3,34.1-10.7,85.9-39.3,119.7
            c-32.2,38.2-66.5,61.7-104.9,71.9C529.8,1205,213.4,1278.2,213.4,1278.2z M587.4,1361c11.1-2.9,22.2-5.7,33.3-8.5
            c2.1,8.2,4.1,16.5,6.2,24.7c-11,2.8-22.1,5.6-33.1,8.4l26.4,101.3c-9.3,2.4-18.6,4.9-27.9,7.4l-57.1-211.2
            c29.6-8,59.3-15.6,89.1-22.8c2,8.2,4,16.5,6,24.7c-19.6,4.7-39.2,9.6-58.7,14.7C576.8,1320.2,582.1,1340.6,587.4,1361L587.4,1361z
            M435.6,1517.8c-2.4,17.5-14.1,29.5-34.8,36.6c-20.4,6.9-37.3,4.5-50.9-7.7c-6.4-6.2-17.3-25.4-32.7-57.4c-3.9-8.9-6.7-16-8.6-21.3
            c-2.6-7.8-4.6-13.9-6-18.4c-10-32.5-14-54.9-11.9-67.6c3.3-17.4,16.1-30.1,38.5-37.7c21.6-7.3,39-4.5,51.8,8.5
            c5.4,6.3,10.2,13.1,14.1,20.4c-10.5,3.4-20.9,6.8-31.4,10.3c-1.2-1.7-3.2-4.4-6.1-8c-5.9-6.3-12.6-8.2-20.2-5.6
            c-9.6,3.3-14.6,9-14.9,17.3c-0.5,10.2,3.1,29.6,10.9,58.2c1.1,3.7,2.1,7.3,3.2,11c1.8,5.2,3.3,9.1,4.4,11.8
            c13.2,30,23.6,49,31.4,57.1c5.1,4.8,11.4,5.8,19.2,3.2c8.6-2.9,13.2-7.5,13.9-13.9c0.4-5.8,0-11.5-1.2-17.2
            c9.9-3.3,19.7-6.5,29.6-9.7C436.1,1499.6,436.7,1509.6,435.6,1517.8L435.6,1517.8z M467.3,1507.2c-7.2-7.3-16.7-25.7-28.5-55.2
            c-4-10.3-7.3-19.4-9.9-27.6c-11.5-36.2-16.2-63.7-13.9-82.5c1.2-8,5.2-15.3,11.8-21.9c6.6-6.6,15.4-11.6,26.1-14.8
            c22.9-6.8,40.3-3.4,52.1,10c12.3,14.5,24.2,42.6,35.8,84.2c1.9,6.7,3.3,12.4,4.2,17.3c4,21.4,6,32.2,6.1,32.4
            c1.9,14,2.1,25.1,0.8,33.3c-4,17.8-15.9,29.5-35.8,35.4C495.9,1523.7,479.7,1520.3,467.3,1507.2L467.3,1507.2z M878.4,1910
            l-79.8-82.4l-4.1,4c-4.4,4.3-12.1,3.5-17.2-1.8s-5.6-12.9-1.2-17.2l4.1-4l-0.9-1l-2.4,2.3c-4,3.9-10.3,3.8-14.1-0.2c0,0,0,0,0,0
            c-3.9-4-3.8-10.3,0.2-14.1c0,0,0,0,0,0l2.4-2.4l-2.9-3c-8.6-5.5-19.4-6.1-28.5-1.5c-15.6,7.7-63,24.6-88.8,10.6
            c-33.4-18.2-47.5-82-76.8-102.2c-8.6-0.4,0.6,26.4,0.6,26.4l-43.2-49.6c16.6,1.7,47.7,6.2,68.5,28.5c22.6,24.3,37.6,58.4,52.8,71.5
            c20.8,17.8,71.4,1.2,78.2-14.1c1.2-2.8,3.9-7.9-0.2-13.6c-27.4-38.6-116.8-124-116.8-124c-2.5-2.3-4.9,9.9-6.3,20.8
            c-10.3-23.6-19.1-47.8-26.5-72.4l-0.4-0.4l0.3,0.1c0-0.1-0.1-0.2-0.1-0.3l0.4,0.4c24.4,8.2,48.3,17.8,71.5,28.8
            c-11,1.1-23.2,3-21,5.6c0,0,82.3,92.2,120,120.8c5.6,4.2,10.8,1.8,13.6,0.6c15.5-6.3,33.8-56.4,16.7-77.8
            c-12.6-15.7-46.2-31.8-69.8-55.2c-21.7-21.5-25-52.8-26.2-69.4l48.2,44.8c0,0-26.5-10-26.4-1.4c19.3,29.9,82.6,46.1,99.7,80.1
            c13.1,26.2-5.4,73-13.6,88.4c-4.8,9-4.6,19.8,0.6,28.5l2.9,3l2.5-2.4c4-3.9,10.3-3.8,14.1,0.2c0,0,0,0,0,0c3.9,4,3.8,10.3-0.2,14.1
            c0,0,0,0,0,0l-2.5,2.4l0.9,1l4.2-4c4.4-4.3,12.1-3.5,17.1,1.8s5.6,12.9,1.2,17.2l-4.2,4L932.5,1910H878.4z M937.2,1669.3
            l-18.2-76.9h-12.7l0.2,76.5h-21.3v-164h36.1c7.3-0.1,14.5,1.8,20.7,5.5c10.7,7.7,16,20.8,16,39.4c0.1,8.3-1.8,16.6-5.5,24
            c-2.9,5.7-6.4,9.8-10.5,12.3c-0.9,0.7-1.7,1.3-2.7,1.8l19.3,81.4L937.2,1669.3z M1053.7,1669h-65v-164.1h63v19.1h-41.8v47.4h24.2
            v19.1h-24.2v59.5h43.8V1669z M1049.1,1348c-1.5-11.7,0.4-23.6,5.5-34.2c4.3-8.6,11.6-17.8,22.2-27.7c-2.7-3.4-7.8-10.2-15.3-20.3
            c-7.3-9.6-11.9-21-13.3-33c-1.5-13.7,0.9-25.3,7.5-34.6c7.2-9.9,18.1-15.7,32.6-17.1c14.2-1.4,25.5,2,33.7,10
            c7.4,7.4,11.6,17.8,12.7,31.2c-9.5,0.8-19.1,1.7-28.6,2.6c-1.9-11.1-7-16.4-15.5-15.5c-9.8,1-14,7.9-12.7,20.8
            c0.8,7.8,4.8,16.5,12,26c5,6.7,12.5,16.6,22.4,29.8c12,16.1,23.2,32.1,33.8,48c1.3-4,1.8-8.3,1.6-12.5c-0.1-1.4-0.2-2.7-0.4-4.1
            c9.1-0.8,18.2-1.5,27.2-2.2l0.4,5.5c1,13.9-1.7,28.2-8,42.6c6.8,10,13.5,20,20.2,30c-10.6,0.8-21.3,1.6-31.9,2.5
            c-2-3.1-4-6.3-6.1-9.4c-8.4,10-21.8,16-40.6,18c-16.1,1.6-29.7-3.4-41.1-15.3C1057.2,1378.5,1051,1364.8,1049.1,1348L1049.1,1348z
            M1077.9,1669v-164.1h31.8c16.8,0,27.9,5.7,33.2,17.2c1.9,5.1,3.6,12.4,5.1,22c1.4,8.7,2.3,17.5,2.5,26.2c0.3,5.9,0.4,11,0.4,15.4
            c0,3.4-0.1,9-0.4,16.8c-1.2,24.2-3.9,40.7-8,49.6c-5.6,11.2-16.8,16.8-33.6,16.8L1077.9,1669z M1177.5,1882.1h-53.4v-134.7h51.7
            v15.6h-34.3v38.9h19.9v15.6h-19.9v48.8h36L1177.5,1882.1z M1237.9,1873.6c-5.7,6.3-13.7,9.4-23.9,9.4c-8.4,0-15.1-3.1-20.1-9.4
            c-4.8-5.9-7.3-13.7-7.6-23.2h18c0,4.5,1.1,8.2,3.2,11.1c1.8,2.7,4.8,4.3,8,4.4c7.7,0,11.5-4.7,11.5-14.2c0.1-6.2-1.5-12.2-4.4-17.6
            c-2.4-4.2-6.2-9-11.6-14.7c-15.8-15.8-23.7-30.7-23.7-44.8c0-8.2,2.1-14.7,6.4-19.7c5-5.7,12.6-8.6,22.9-8.6
            c9.1,0,16.2,3.1,21.2,9.4c4.3,5.1,6.7,11.9,7.2,20.2h-17c-0.8-8.5-4.8-12.8-12-12.8c-7.7,0-11.6,4-11.6,11.8c0,5.6,2,11.3,6.1,17.2
            c2.1,3.2,5.8,7.6,11.1,13c9.8,10.1,16.4,19,19.9,26.8c2.6,5.9,3.9,13,3.9,21.2C1245.6,1860.7,1242.9,1868,1237.9,1873.6
            L1237.9,1873.6z M1246.3,1669H1178v-164.1h21.1v145.2h47.2V1669z M1312.2,1763.1h-18.5v119h-18V1763h-20.4v-15.6h56.9
            L1312.2,1763.1z M1318,1624.1h-23.4l-10.5,44.9h-21.7l34.9-164.1h18l34,164.1h-21.5L1318,1624.1z M1348.2,1881.5
            c-1.6,1.7-3.9,2.6-6.2,2.5c-2.3,0.1-4.6-0.9-6.2-2.5c-1.7-1.6-2.6-3.9-2.5-6.2c0-2.3,0.9-4.6,2.5-6.2c1.7-1.7,3.9-2.6,6.2-2.5
            c2.3,0,4.6,0.9,6.2,2.5c1.7,1.6,2.6,3.9,2.5,6.2C1350.8,1877.6,1349.9,1879.9,1348.2,1881.5L1348.2,1881.5z M1358.1,1166.5
            c30.4-0.6,60.9-0.7,91.3-0.4c-0.1,8.5-0.2,16.9-0.2,25.4c-20.1-0.2-40.1-0.2-60.2,0c0.2,21,0.4,42.1,0.6,63.1
            c11.4-0.1,22.7-0.1,34.1-0.1c0,8.5,0,17,0,25.5c-11.3,0-22.6,0-33.8,0.1c0.2,26.4,0.5,52.8,0.7,79.2c20-0.2,40-0.2,60,0
            c-0.1,8.5-0.2,17-0.2,25.5c-29.4-0.3-58.8-0.2-88.2,0.3C1360.7,1312.3,1359.3,1239.4,1358.1,1166.5L1358.1,1166.5z M1399.1,1669
            h-21.1v-163.7h21.1l29.9,92.5v-92.5h21.1V1669H1429l-29.9-97.2V1669z M1469.4,1866.3v15.7H1409v-7.6c-0.1-10.4,2-20.7,6.2-30.1
            c2.8-6.4,7.7-14,14.5-22.9c8.8-11.6,14.6-19.4,17.2-23.6c4.6-7.3,6.9-14.1,6.9-20.3c0.2-3.6-0.9-7.1-3.1-10c-1.9-2.5-5-3.9-8.1-3.8
            c-4.2-0.2-8.2,2.1-10.1,5.9c-1.8,3.5-2.6,7.5-2.4,11.4h-17.3c0-9.7,2.3-17.7,6.9-24.2c5.4-7.4,13-11.2,22.9-11.3
            c9.1-0.1,16.3,2.9,21.7,9.1c4.9,5.8,7.4,13.2,7.4,22c-0.1,8.4-2.2,16.7-6.2,24.1c-2.7,5-7.4,11.9-14.2,20.8
            c-6.7,8.8-11.5,15.7-14.3,20.6c-4.3,7.4-7,15.6-7.9,24.1L1469.4,1866.3z M1546.6,1828.3c-1.1,12.9-2.3,21.9-3.5,26.9
            c-2.1,9.8-5.7,17.1-10.7,21.9c-4.8,3.8-9.2,5.9-13,6.2c-1.3,0.2-2.7,0.3-4.1,0.2c-1.3,0.1-2.7,0-4-0.2c-5.3-0.6-10.1-3.1-13.8-6.9
            c-4.8-4.8-8.2-11.7-10.1-20.5c-0.9-4.5-1.8-13-2.7-25.4c-0.5-5.7-0.7-11.2-0.7-16.3c-0.1-5,0-9.5,0.2-13.6
            c0.6-11.7,1.7-20.8,3.2-27.2c2-8.7,5.4-15.4,10.3-20.4c4-3.9,9.1-6.2,14.6-6.7c1.1-0.1,2.1-0.2,3-0.2c0.7,0,1.7,0.1,3.1,0.2
            c6.6,0.9,11.6,3.1,15,6.6c5,6.3,8.6,13.7,10.4,21.6c1.4,5.8,2.4,14.6,3,26.2c0,0.8,0.1,5.3,0.2,13.6
            C1547.1,1818.8,1547,1823.5,1546.6,1828.3L1546.6,1828.3z M1559.6,1602.6c-1.2,24.2-3.9,40.7-8,49.6c-5.6,11.2-16.8,16.8-33.6,16.8
            h-31v-164.2h31.8c16.8,0,27.9,5.7,33.2,17.2c1.9,5.1,3.6,12.4,5.1,22c1.4,8.7,2.2,17.5,2.5,26.2c0.3,5.9,0.4,11,0.4,15.4
            C1560,1589.1,1559.9,1594.7,1559.6,1602.6L1559.6,1602.6z M1622.9,1828.3c-1.1,12.9-2.3,21.9-3.5,26.9c-2.1,9.8-5.7,17.1-10.8,21.9
            c-4.8,3.8-9.1,5.9-13,6.2c-1.3,0.2-2.7,0.3-4,0.2c-1.3,0.1-2.7,0-4-0.2c-5.3-0.6-10.1-3.1-13.8-6.9c-4.8-4.8-8.2-11.7-10.1-20.5
            c-0.9-4.5-1.8-13-2.7-25.4c-0.5-5.7-0.7-11.2-0.7-16.3c-0.1-4.9-0.1-9.5,0.2-13.6c0.6-11.7,1.6-20.8,3.2-27.2
            c2-8.7,5.5-15.4,10.3-20.4c4-3.9,9.2-6.2,14.7-6.7c1.1-0.1,2.1-0.2,3-0.2c0.7,0,1.7,0.1,3,0.2c6.7,0.9,11.6,3.1,15,6.6
            c5.1,6.3,8.6,13.7,10.4,21.6c1.5,5.8,2.5,14.6,3.1,26.2c0,0.8,0.1,5.3,0.1,13.6C1623.4,1818.8,1623.3,1823.5,1622.9,1828.3
            L1622.9,1828.3z M1698.1,1843.7h-9v38.6h-17.3v-38.6h-39v-15.3l37.7-81h18.6v80.6h9L1698.1,1843.7z M1704.6,1666.8
            c-0.7,4.8-2.9,9.2-6.4,12.5c-1.8,1.8-3.9-1-6.3-3.9l1.8-3.9c-2.8,0.1-5.6-1-7.6-3.1c-2-2-3.1-4.7-3.1-7.6c-0.1-2.8,1.1-5.6,3.1-7.6
            c2-2,4.7-3.1,7.6-3.1c6.3,0,9.9,3.6,10.9,10.7C1704.9,1662.8,1704.9,1664.8,1704.6,1666.8L1704.6,1666.8z M1743,1379.2
            c-9.3,15-24.6,21.4-46.2,19.5c-21.2-1.9-35.5-10.9-42.8-27.6c-3.3-8.3-5.5-30.2-6.6-65.7c0.1-9.7,0.3-17.3,0.7-23
            c0.8-8.2,1.5-14.6,2-19.3c3.9-33.8,9.3-55.9,16.2-66.6c10-14.6,26.6-21,49.7-19c22.6,2,37.1,11.6,43.4,28.6
            c2.5,7.9,4,16.1,4.6,24.4c-10.8-1.1-21.6-2.2-32.4-3.2c-0.4-2-1.2-5.3-2.4-9.8c-2.7-8.2-8.1-12.6-15.9-13.3
            c-10-0.9-16.8,2.4-20.4,9.8c-4.5,9.1-9,28.3-13.4,57.6l-1.5,11.4c-0.4,5.5-0.7,9.7-0.7,12.6c-0.1,32.8,1.7,54.4,5.6,64.9
            c2.7,6.5,8,10,16,10.7c8.9,0.8,15-1.5,18.1-7.1c2.7-5.1,4.6-10.6,5.8-16.2c10.2,1,20.5,2,30.7,3.1
            C1750.8,1362.6,1747.2,1372,1743,1379.2L1743,1379.2z M1832.2,1652.4c-5.6,11.9-16.4,17.8-32.4,17.8c-15.7,0-26.7-6-33-18
            c-2.9-6-5.6-22.3-8.2-48.8c-0.4-7.2-0.6-13-0.6-17.2c0.1-6.1,0.3-11,0.4-14.6c1.1-25.4,3.8-42.3,8-50.7
            c6.2-11.5,17.2-17.2,33.2-17.2c15.6,0,26.2,6.1,31.8,18.3c2.2,5.8,3.9,11.7,4.9,17.8h-22.8c-0.4-1.5-1.2-3.9-2.2-7.2
            c-2.5-5.9-6.4-8.8-11.9-8.8c-7,0-11.5,2.9-13.5,8.6c-2.6,7.1-4.7,21.7-6.2,43.9l-0.4,8.6c0,4.1,0.1,7.2,0.2,9.4
            c1.8,24.5,4.4,40.5,7.8,48.2c2.3,4.7,6.4,7,12.3,7c6.6,0,10.8-2.2,12.7-6.6c1.6-4,2.6-8.2,3.1-12.5h22.3
            C1836.6,1639.5,1834.8,1646.7,1832.2,1652.4L1832.2,1652.4z M1852.5,1669l34.9-164.1h18l34,164.1h-21.5l-9.8-44.9h-23.3l-10.5,44.9
            H1852.5z M2244.4,1723.6c0,0,9.1-26.8,0.6-26.4c-29.2,20.3-43.4,84.1-76.8,102.2c-25.8,14-73.2-2.9-88.8-10.6
            c-9.1-4.5-19.9-3.9-28.5,1.5l-2.9,3l2.4,2.4c4,3.8,4.1,10.2,0.3,14.1c0,0,0,0,0,0c-3.8,4-10.2,4.1-14.1,0.3c0,0,0,0,0,0l-2.4-2.3
            l-0.9,1l4.1,4c4.4,4.3,3.9,12-1.2,17.2s-12.7,6-17.2,1.8l-4.1-4l-79.8,82.4h-54.1l107.2-108.2l-4.2-4c-4.4-4.3-3.9-12,1.2-17.2
            s12.7-6,17.2-1.8l4.2,4l0.9-1l-2.5-2.4c-4-3.8-4.1-10.2-0.3-14.1c0,0,0,0,0,0c3.8-4,10.2-4.1,14.1-0.3c0,0,0,0,0,0l2.5,2.4l2.9-3
            c5.2-8.7,5.4-19.6,0.6-28.5c-8.2-15.3-26.7-62.1-13.6-88.4c17-34,80.4-50.2,99.7-80.1c0.1-8.6-26.4,1.4-26.4,1.4l48.2-44.8
            c-1.2,16.7-4.5,47.9-26.2,69.4c-23.6,23.4-57.2,39.5-69.8,55.2c-17.1,21.3,1.2,71.4,16.7,77.8c2.8,1.2,8,3.6,13.6-0.6
            c37.7-28.7,120-120.8,120-120.8c2.2-2.6-10-4.5-21-5.6c23.3-11,47.1-20.7,71.5-28.8l0.4-0.4l-0.1,0.3l0.3-0.1l-0.4,0.4
            c-7.4,24.7-16.2,48.8-26.5,72.4c-1.5-10.9-3.9-23.2-6.3-20.8c0,0-89.3,85.3-116.8,124c-4,5.7-1.4,10.8-0.2,13.6
            c6.9,15.3,57.5,31.9,78.2,14.1c15.2-13.1,30.3-47.2,52.8-71.5c20.8-22.4,51.9-26.8,68.5-28.5L2244.4,1723.6z M2331.2,1519.2
            c-0.6-45.3-1.8-90.7-3.6-136.2c-11.9,41.5-23.8,83.1-35.8,124.6c-9.3-2.7-18.6-5.3-27.9-7.9c19.4-70.1,38.9-140.2,58.3-210.3
            c9.9,2.8,19.9,5.6,29.8,8.4c2.7,43.5,4.8,87,6.5,130.4l35.6-118.1c9.9,3,19.7,6,29.6,9c-21.6,69.5-43.2,139-64.8,208.4
            C2349.7,1524.7,2340.4,1521.9,2331.2,1519.2L2331.2,1519.2z M2479,1464.8l-31.6,91.8c-9.4-3.2-18.8-6.4-28.3-9.6
            c10.2-30.5,20.3-61.1,30.5-91.6c-2.1-43.7-4.8-87.4-8.1-131.2c10.5,3.3,21,6.7,31.6,10.2c1.5,29.3,2.7,58.6,3.6,87.9
            c18.7-22.6,37.7-45.1,56.8-67.5c10.5,3.7,21,7.4,31.5,11.2C2535.9,1398.7,2507.2,1431.5,2479,1464.8L2479,1464.8z M2614.7,1278.2
            c0,0-241.7-65.2-299.2-78.2c-27.4-6.2-50.9-20.4-69.8-42.3c-19.3-22.4-29.2-47-29.3-73.2l0.1-914h255.4v904.4
            c0.1,23.3,10.4,35.3,35.7,41.4c29.6,7.2,59.5,14.6,89,22.2l18.2,4.6L2614.7,1278.2z"/>
          <path className="st1" d="M2223.7,1325.3c-0.7,17.2-1.5,34.4-2.4,51.6c-6.3-1.6-12.5-3.2-18.8-4.7
            C2209.5,1356.6,2216.5,1340.9,2223.7,1325.3z"/>
          <path className="st1" d="M2129.7,1267.5c14.7,3.2,19.7,15.6,14.8,37.2c-4.8,21.4-14.3,30.4-28.8,27.3c-6.7-1.4-13.4-2.9-20.2-4.3
            c4.5-21.5,9-43,13.6-64.5C2116,1264.5,2122.8,1266,2129.7,1267.5z"/>
          <polygon className="st1" points="1896.4,1563.5 1903.6,1601.5 1889.8,1601.5 	"/>
          <path className="st1" d="M1860.7,1230.5c3.4,10.2,4,29.6,1.9,58.2c-0.2,3.1-0.8,7.7-1.6,13.9c-0.6,4.2-1.1,8.3-1.7,12.5
            c-5.2,31.3-11.5,52.6-18.6,63.7c-3.6,5.4-9.2,7.6-17,6.3c-7.2-0.9-12-4.3-14.3-10.3c-4.7-13-5.4-35.1-2.3-66.3
            c0.2-3.1,0.6-7.1,1.2-12c0.8-6.3,1.4-11.1,2-14.4c5-27.5,10.6-46.1,16.6-56.1c4-5.8,10.1-8.2,18.1-7.1
            C1852.9,1219.8,1858.2,1223.8,1860.7,1230.5z"/>
          <polygon className="st1" points="1671.7,1781.7 1671.7,1828 1650,1828 	"/>
          <polygon className="st1" points="1651.5,36.7 1650.9,222.6 1648.6,222.6 1649.2,36.7 	"/>
          <path className="st1" d="M1605.6,1813c0,3.6-0.1,6.2-0.2,7.7c-0.2,6.1-0.5,12.2-0.7,18.2c-0.3,8.1-1.3,14.2-2.9,18.2
            c-2,5.5-5.2,8.2-9.7,8.2c-4.5,0-7.8-2.7-10-8.2c-1.7-4.2-2.8-10.2-3.4-18.2c-0.3-6-0.6-12.1-1-18.2c-0.1-1.5-0.2-4.1-0.2-7.7
            c0-2,0.1-4.9,0.3-8.6c0.2-5.7,0.4-11.4,0.7-17.2c0.5-7.2,1.5-12.8,3.1-16.4c2.1-4.9,5.4-7.4,9.9-7.4c4.4,0,7.6,2.5,9.8,7.4
            c1.5,3.7,2.6,9.2,3.2,16.4c0.2,5.7,0.4,11.5,0.7,17.2C1605.4,1808.1,1605.6,1811,1605.6,1813z"/>
          <polygon className="st1" points="1558.3,477.7 1566.7,640.7 1405.9,640.4 1362.7,477.7 	"/>
          <path className="st1" d="M1538.1,1572.4c0.3,3.4,0.4,8.1,0.4,14c0.1,5.2-0.1,10.4-0.6,15.6c-1.2,14.6-1.9,22.3-2.1,23
            c-1.1,7.7-2.7,13.8-4.7,18.5c-1.9,3-3.8,4.9-5.6,5.5c-1.8,0.7-5.1,1-9.7,1h-7.6V1524h7.6c8.8,0,14.1,2.9,16,8.6
            C1534.8,1542,1537,1555.3,1538.1,1572.4z"/>
          <path className="st1" d="M1526.6,1233.7c2.9,17,5.7,34,8.4,51c-6.4-0.2-12.8-0.4-19.2-0.6C1519.3,1267.3,1523,1250.5,1526.6,1233.7z"/>
          <path className="st1" d="M1529.2,1813c0,3.6-0.1,6.2-0.2,7.7c-0.2,6.1-0.4,12.2-0.7,18.2c-0.4,8.1-1.3,14.2-2.9,18.2
            c-2,5.5-5.2,8.2-9.7,8.2c-4.4,0-7.7-2.7-10-8.2c-1.7-4.2-2.8-10.2-3.4-18.2c-0.3-6-0.7-12.1-1-18.2c-0.1-1.5-0.2-4.1-0.2-7.7
            c0-2,0.1-4.9,0.3-8.6c0.2-5.7,0.5-11.4,0.7-17.2c0.4-7.2,1.4-12.8,3-16.4c2.1-4.9,5.4-7.4,9.9-7.4c4.4,0,7.7,2.5,9.8,7.4
            c1.5,3.7,2.7,9.2,3.2,16.4c0.2,5.7,0.5,11.4,0.7,17.2C1529.1,1808.1,1529.2,1811,1529.2,1813z"/>
          <polygon className="st1" points="1306.3,1563.5 1313.5,1601.5 1299.8,1601.5 	"/>
          <path className="st1" d="M1128.9,1572.4c0.3,3.4,0.4,8.1,0.4,14c0.1,5.2-0.1,10.4-0.6,15.6c-1.2,14.6-1.9,22.3-2.1,23
            c-1.1,7.7-2.7,13.8-4.7,18.5c-1.9,3-3.8,4.9-5.6,5.5c-1.9,0.7-5.1,1-9.7,1h-7.6V1524h7.6c8.8,0,14.1,2.9,16,8.6
            C1125.7,1542,1127.8,1555.3,1128.9,1572.4z"/>
          <path className="st1" d="M1131.4,1362.3c-4,4.2-7.2,7-10,8.3s-7,2.3-13,2.9c-6.9,0.7-13.2-2.1-19-8.3c-5.8-6.2-9.3-14.3-10.4-24.5
            c-1.1-10.2,3.8-20.6,14.6-31.2C1106.4,1327.1,1119,1344.7,1131.4,1362.3z"/>
          <path className="st1" d="M919.5,1524c10.4,0,15.6,8.3,15.6,24.8c0,16.4-5.2,24.6-15.6,24.6h-13.3V1524H919.5z"/>
          <path className="st1" d="M513.2,1413.1c7.9,30.7,10.7,52.7,8.7,65.8c-1.1,6.4-5.4,10.7-13.1,12.7c-7,2.1-12.9,0.9-17.4-3.7
            c-9.6-10-19.2-29.9-29-59.7c-1.1-2.9-2.3-6.7-3.8-11.4c-1.9-6.1-3.2-10.7-4-14c-6.5-27.2-9-46.5-7.4-58c1.4-6.9,6-11.6,13.8-13.9
            s14.2-0.8,19.3,4.3c7.3,8,15.7,25.4,25.3,52.4c1,3,2.4,7.4,4.1,13.3C510.8,1405.1,512,1409.1,513.2,1413.1z"/>
        </g>
        <g id="Layer_3" className="st0">
          <path className="st3" d="M83,1418.6l146.7-44.7l90.6,276c0,0,112.6-25.3,173.8,78.2s103.8,203.8,248.6,237c0,0,55.2,22.3,177.9,16.7
            c100,0,969.2,0.9,969.2,0.9s205,14.8,305.5-97c94.6-111,82.2-135.6,112.5-168.6c30.4-33,50.4-72.4,171.6-67.2l131.5-267.8
            l137.7,39.2v-382.2l-143.2-36.4l0.5-966h-956.7V230H1158V36.7c0,0-755.9,2.5-867.9,2.5S21.7,189.4,50.4,380.1
            c34.4,158.8,245.8,573.6,245.8,573.6l-216.6,56.9L83,1418.6z"/>
        </g>
        <g id="Layer_2">
          <path className="st4" d="M213.4,1278.2V1117l18.9-4.4c77.7-18.5,155.8-35.5,232-50.4c16.9-3.3,19.4-10.5,20.4-13.6
            c2-5.8-0.3-15.7-6.5-27.9C384.4,831.9,296.7,646,209.9,452.1c-19.4-42.9-29.2-84.4-29.1-123.3c0.1-44.4,13-81.9,38.1-111.5
            c26-30.5,63-46.2,109.9-46.8h377.2l-0.7,122H448.5c-11,0.3-15.6,4.2-18.6,9.9c-1.1,4.6,0.2,14.5,7.2,29.6
            C523.6,518.9,611.7,700,706.5,885.8c19.6,38.4,29.5,75.9,29.3,111.3c-0.3,34.1-10.7,85.9-39.3,119.7
            c-32.2,38.2-66.5,61.7-104.9,71.9C529.8,1205,213.4,1278.2,213.4,1278.2z"/>
          <path className="st4" d="M1024,170.6v193.3h59.4l-0.6,122.2h-59.3c-0.9,154.3-1.7,308.6-2.6,462.9c0,3.5,0.3,9.8,3,12.5
            s9.7,4.3,18.6,4.3c2.3,0,4.7-0.1,7.3-0.3c16.7-1.3,32.8-2.5,52.1-3.8l25.9-1.8l-0.5,115.3l-22.3,1.6
            c-77.2,5.6-156.5,13.1-235.9,22.2c-5.1,0.6-10.3,0.9-15.5,0.9c-22.3,0-42.1-6-58.6-18c-21.9-15.8-33.4-38.3-33.2-65.1l-0.1-846.2
            L1024,170.6z"/>
          <path className="st4" d="M1746.6,961.6l-22.5-1.4c-77.6-5-232.8-10.4-232.8-10.4l-54.3-194.9l308.6,9.3l-1.3-299.5
            c-0.1-19.4-7.2-47.6-40.9-71.8c-26.1-18.8-54.1-28.6-83.3-29h-406c-31.4,0.2-57.9,9-78.9,26c-22.2,18-34,41.8-34.1,68.9
            c0,8.6,1.5,17.2,4.3,25.4c41.1,148.1,85.3,305.7,143.3,511.1c11.4,43.8,41.6,67.9,85.1,67.9c129,0,259.3,4.5,387.4,13.3l25.9,1.8
            L1746.6,961.6z M1362.7,477.7h195.6l8.4,163l-160.8-0.3L1362.7,477.7z"/>
          <path className="st4" d="M1782.7,170.6h258.1c0,0-1.9,548.1-0.1,808.2c0.1,12.5,2.8,26.2,34.5,30.7c30.4,4.4,60.9,9,90.6,13.7l20.2,3.2
            l0.8,122.5l-28.3-4.9c-91.3-15.7-183.2-29.1-273.2-40c-28-3.4-52-14.6-71.4-33.4c-20.5-19.6-31-42.5-31.2-67.9L1782.7,170.6z"/>
          <path className="st4" d="M2596.5,1138.6l18.2,4.6l-0.1,135c0,0-241.7-65.2-299.2-78.2c-27.4-6.2-50.9-20.4-69.8-42.3
            c-19.3-22.4-29.2-47-29.3-73.2l0.1-914h255.4v904.4c0.1,23.3,10.4,35.3,35.7,41.4C2537.1,1123.6,2567,1131,2596.5,1138.6"/>
          <path d="M921.2,1292.2c11.3-1.7,22.6-3.4,33.8-5c1.2,8.4,2.4,16.8,3.6,25.2c-11.2,1.6-22.4,3.2-33.6,4.9c4,26.1,8,52.2,12,78.3
            c19.8-3,39.6-5.9,59.5-8.5c1.1,8.4,2.2,16.8,3.4,25.3c-29.2,3.9-58.3,8.2-87.4,12.9c-11.7-72-23.3-144-35-216
            c30.1-4.9,60.3-9.3,90.5-13.4l3.4,25.2c-19.9,2.7-39.8,5.5-59.7,8.6C914.9,1250.6,918,1271.4,921.2,1292.2z"/>
          <path d="M851.6,1213.7L851.6,1213.7c1.4,8.4,2.8,16.7,4.3,25.1c-19.9,3.4-39.7,6.9-59.5,10.6c3.9,20.7,7.8,41.4,11.7,62.1
            c11.3-2.1,22.5-4.2,33.8-6.2c1.5,8.4,3,16.7,4.5,25.1c-11.2,2-22.3,4-33.5,6.1c4.9,25.9,9.8,51.9,14.7,77.9
            c19.7-3.7,39.5-7.2,59.3-10.6c1.4,8.4,2.8,16.8,4.2,25.1c-29.1,4.9-58.1,10.2-87,16c-14.2-71.6-28.3-143.1-42.4-214.7
            C791.5,1224.4,821.5,1218.9,851.6,1213.7"/>
          <path d="M742.8,1260c-19.8,4.1-39.5,8.3-59.2,12.7c4.6,20.5,9.2,41.1,13.8,61.6c11.2-2.5,22.4-4.9,33.6-7.3
            c1.8,8.3,3.5,16.6,5.3,24.9c-11.1,2.4-22.2,4.8-33.3,7.3c7.6,34.1,15.3,68.1,22.9,102.2c-9.3,2.1-18.7,4.2-28,6.4l-49.8-213
            c29.8-7,59.7-13.5,89.6-19.7C739.4,1243.4,741.1,1251.7,742.8,1260z"/>
          <path d="M587.4,1361c11.1-2.9,22.2-5.7,33.3-8.5c2.1,8.2,4.1,16.5,6.2,24.7c-11,2.8-22.1,5.6-33.1,8.4l26.4,101.3
            c-9.3,2.4-18.6,4.9-27.9,7.4l-57.1-211.2c29.6-8,59.3-15.6,89.1-22.8c2,8.2,4,16.5,6,24.7c-19.6,4.7-39.2,9.6-58.7,14.7
            C576.8,1320.2,582.1,1340.6,587.4,1361L587.4,1361"/>
          <path d="M435.6,1517.8L435.6,1517.8c-2.4,17.5-14.1,29.5-34.8,36.6"/>
          <path d="M400.7,1554.4c-20.4,6.9-37.3,4.5-50.9-7.7c-6.4-6.2-17.3-25.4-32.7-57.4c-3.9-8.9-6.7-16-8.6-21.3
            c-2.6-7.8-4.6-13.9-6-18.4c-10-32.5-14-54.9-11.9-67.6c3.3-17.4,16.1-30.1,38.5-37.7c21.6-7.3,39-4.5,51.8,8.5
            c5.4,6.3,10.2,13.1,14.1,20.4c-10.5,3.4-20.9,6.8-31.4,10.3c-1.2-1.7-3.2-4.4-6.1-8c-5.9-6.3-12.6-8.2-20.2-5.6
            c-9.6,3.3-14.6,9-14.9,17.3c-0.5,10.2,3.1,29.6,10.9,58.2c1.1,3.7,2.1,7.3,3.2,11c1.8,5.2,3.3,9.1,4.4,11.8
            c13.2,30,23.6,49,31.4,57.1c5.1,4.8,11.4,5.8,19.2,3.2c8.6-2.9,13.2-7.5,13.9-13.9c0.4-5.8,0-11.5-1.2-17.2
            c9.9-3.3,19.7-6.5,29.6-9.7c2.2,11.9,2.8,22,1.6,30.2"/>
          <path d="M551.2,1449c-0.1-0.2-2.1-11-6.1-32.4c-1-4.9-2.4-10.6-4.2-17.3c-11.6-41.6-23.5-69.7-35.8-84.2
            c-11.9-13.4-29.2-16.8-52.1-10c-10.8,3.2-19.5,8.2-26.1,14.8c-6.6,6.6-10.6,13.9-11.8,21.9c-2.3,18.9,2.4,46.3,13.9,82.5
            c2.6,8.2,5.9,17.3,9.9,27.6c11.8,29.5,21.2,47.9,28.5,55.2l0,0c12.4,13.1,28.6,16.5,48.9,10.5c19.9-5.9,31.8-17.6,35.8-35.4
            C553.3,1474.1,553.1,1463,551.2,1449z M521.9,1478.9c-1.1,6.4-5.4,10.7-13.1,12.7c-7,2.1-12.9,0.9-17.4-3.7
            c-9.6-10-19.2-29.9-29-59.7c-1.1-2.9-2.3-6.7-3.8-11.4c-1.9-6.1-3.2-10.7-4-14c-6.5-27.2-9-46.5-7.4-58c1.4-6.9,6-11.6,13.8-13.9
            s14.2-0.8,19.3,4.3c7.3,8,15.7,25.4,25.3,52.4c1,3,2.4,7.4,4.1,13.3c1.2,4.1,2.3,8.1,3.5,12.2
            C521.1,1443.9,523.9,1465.8,521.9,1478.9z"/>
          <path d="M1515.8,1284.1c3.6-16.8,7.2-33.6,10.8-50.4"/>
          <path d="M1526.6,1233.7c2.9,17,5.7,34,8.4,51"/>
          <g>
            <path className="st4" d="M464.3,1062.2c-76.2,15-154.3,31.9-232,50.4l-18.9,4.4v161.2c0,0,316.4-73.2,378.2-89.4
              c38.4-10.1,72.7-33.7,104.9-71.9c28.6-33.8,39-85.6,39.3-119.8c0.2-35.4-9.6-72.9-29.3-111.3C611.7,700,523.6,518.9,437.2,332
              c-7-15-8.4-24.9-7.2-29.6c3-5.8,7.6-9.6,18.6-9.9h256.9l0.7-122H328.9C282,171.1,245,186.9,219,217.4
              c-25.1,29.6-38,67.2-38.1,111.5c-0.1,38.8,9.7,80.3,29.1,123.3c86.8,193.8,174.5,379.8,268.4,568.6c6.2,12.2,8.5,22.1,6.5,27.9
              C483.7,1051.7,481.2,1058.9,464.3,1062.2z"/>
            <path className="st4" d="M630.3,1285.1c-2-8.2-4-16.5-6-24.7c-29.8,7.2-59.5,14.8-89.1,22.8l57.1,211.2c9.3-2.5,18.6-5,27.9-7.4
              l-26.4-101.3c11-2.9,22-5.7,33.1-8.4c-2.1-8.3-4.1-16.5-6.2-24.7c-11.1,2.8-22.2,5.6-33.3,8.5c-5.3-20.4-10.6-40.7-15.9-61.1
              C591.1,1294.8,610.7,1289.9,630.3,1285.1z"/>
            <path className="st4" d="M404.3,1497.3c1.2,5.6,1.6,11.4,1.2,17.2c-0.7,6.4-5.3,11-13.9,13.9c-7.7,2.6-14.1,1.6-19.2-3.2
              c-7.8-8.1-18.3-27.1-31.4-57.1c-1.1-2.7-2.6-6.6-4.4-11.8c-1.1-3.7-2.1-7.3-3.2-11c-7.7-28.6-11.4-48-10.9-58.2
              c0.3-8.3,5.2-14,14.9-17.3c7.6-2.6,14.3-0.7,20.2,5.6c2.9,3.6,4.9,6.3,6.1,8c10.4-3.5,20.9-6.9,31.4-10.3
              c-3.9-7.3-8.7-14.1-14.1-20.4c-12.8-12.9-30.2-15.8-51.8-8.5c-22.3,7.6-35.1,20.3-38.5,37.7c-2.1,12.6,1.9,35.1,11.9,67.6
              c1.4,4.5,3.4,10.7,6,18.4c1.9,5.3,4.8,12.4,8.6,21.3c15.4,32,26.3,51.2,32.7,57.4c13.6,12.2,30.5,14.7,50.9,7.7
              c20.8-7.1,32.4-19.1,34.8-36.6h0c1.1-8.2,0.6-18.3-1.6-30.2C424.1,1490.8,414.2,1494,404.3,1497.3z"/>
            <path className="st4" d="M697.9,1467.8c9.3-2.2,18.7-4.3,28-6.4c-7.6-34.1-15.3-68.1-22.9-102.2c11.1-2.5,22.2-4.9,33.3-7.3
              c-1.8-8.3-3.6-16.6-5.3-24.9c-11.2,2.4-22.4,4.8-33.6,7.3c-4.6-20.5-9.2-41.1-13.8-61.6c19.7-4.4,39.4-8.6,59.2-12.7
              c-1.7-8.3-3.4-16.6-5.1-24.8c-29.9,6.1-59.8,12.7-89.6,19.7L697.9,1467.8z"/>
            <path className="st4" d="M890.9,1429c-1.4-8.4-2.8-16.8-4.2-25.1c-19.8,3.3-39.5,6.9-59.3,10.6c-4.9-26-9.8-51.9-14.7-77.9
              c11.2-2.1,22.3-4.1,33.5-6.1c-1.5-8.4-3-16.7-4.5-25.1c-11.2,2-22.5,4.1-33.8,6.2c-3.9-20.7-7.8-41.4-11.7-62.1
              c19.8-3.7,39.6-7.3,59.5-10.6c-1.4-8.4-2.8-16.7-4.2-25.1h0c-30.1,5.1-60.1,10.6-90.1,16.6c14.1,71.5,28.3,143.1,42.4,214.7
              C832.9,1439.2,861.9,1433.9,890.9,1429z"/>
            <path className="st4" d="M829.5,1797.7c4.4-4.3,3.9-12-1.2-17.2s-12.7-6-17.1-1.8l-4.2,4l-0.9-1l2.5-2.4c0,0,0,0,0,0
              c4-3.9,4-10.2,0.2-14.1c0,0,0,0,0,0c-3.9-4-10.2-4-14.1-0.2l-2.5,2.4l-2.9-3c-5.2-8.7-5.5-19.6-0.6-28.5
              c8.2-15.4,26.7-62.1,13.6-88.4c-17-34-80.3-50.2-99.7-80.1c-0.1-8.6,26.4,1.4,26.4,1.4l-48.2-44.8c1.2,16.7,4.6,47.9,26.2,69.4
              c23.6,23.4,57.2,39.5,69.8,55.2c17.1,21.3-1.1,71.4-16.7,77.8c-2.8,1.2-8,3.6-13.6-0.6c-37.7-28.7-120-120.8-120-120.8
              c-2.2-2.6,10-4.5,21-5.6c-23.3-11-47.1-20.7-71.5-28.8l-0.4-0.4c0,0.1,0.1,0.2,0.1,0.3l-0.3-0.1l0.4,0.4
              c7.4,24.7,16.2,48.8,26.5,72.4c1.5-10.9,3.9-23.1,6.3-20.8c0,0,89.4,85.4,116.8,124c4,5.7,1.4,10.8,0.2,13.6
              c-6.9,15.3-57.5,31.9-78.2,14.1c-15.2-13.1-30.3-47.2-52.8-71.5c-20.8-22.4-51.9-26.8-68.5-28.5l43.2,49.6c0,0-9.1-26.8-0.6-26.4
              c29.2,20.2,43.4,84.1,76.8,102.2c25.8,14,73.2-2.9,88.8-10.6c9.1-4.5,19.9-3.9,28.5,1.5l2.9,3l-2.4,2.4c0,0,0,0,0,0
              c-4,3.9-4,10.2-0.2,14.1c0,0,0,0,0,0c3.9,4,10.2,4,14.1,0.2l2.4-2.3l0.9,1l-4.1,4c-4.4,4.3-3.9,12,1.2,17.2s12.8,6,17.2,1.8l4.1-4
              l79.8,82.4h54.1l-107.2-108.3L829.5,1797.7z"/>
            <polygon className="st4" points="1009.8,1590.4 1034,1590.4 1034,1571.4 1009.8,1571.4 1009.8,1524 1051.7,1524 1051.7,1504.9 
              988.7,1504.9 988.7,1669 1053.7,1669 1053.7,1649.9 1009.8,1649.9 		"/>
            <path className="st4" d="M968,1196c-30.2,4.1-60.4,8.5-90.5,13.4c11.7,72,23.3,144,35,216c29.1-4.7,58.2-9,87.4-12.9
              c-1.1-8.4-2.3-16.8-3.4-25.3c-19.9,2.7-39.7,5.5-59.5,8.5c-4-26.1-8-52.2-12-78.3c11.2-1.7,22.4-3.4,33.6-4.9
              c-1.2-8.4-2.4-16.8-3.6-25.2c-11.3,1.6-22.6,3.3-33.8,5c-3.2-20.8-6.3-41.6-9.5-62.5c19.9-3,39.7-5.9,59.7-8.6L968,1196z"/>
            <path className="st4" d="M552,1482.3c1.3-8.2,1.1-19.3-0.8-33.3c-0.1-0.2-2.1-11-6.1-32.4c-1-4.9-2.4-10.6-4.2-17.3
              c-11.6-41.6-23.5-69.7-35.8-84.2c-11.9-13.4-29.2-16.8-52.1-10c-10.8,3.2-19.5,8.2-26.1,14.8c-6.6,6.6-10.6,13.9-11.8,21.9
              c-2.3,18.9,2.4,46.3,13.9,82.5c2.6,8.2,5.9,17.3,9.9,27.6c11.8,29.5,21.2,47.9,28.5,55.2l0,0c12.4,13.1,28.6,16.5,48.9,10.5
              C536.1,1511.8,548,1500.1,552,1482.3z M521.9,1478.9c-1.1,6.4-5.4,10.7-13.1,12.7c-7,2.1-12.9,0.9-17.4-3.7
              c-9.6-10-19.2-29.9-29-59.7c-1.1-2.9-2.3-6.7-3.8-11.4c-1.9-6.1-3.2-10.7-4-14c-6.5-27.2-9-46.5-7.4-58c1.4-6.9,6-11.6,13.8-13.9
              s14.2-0.8,19.3,4.3c7.3,8,15.7,25.4,25.3,52.4c1,3,2.4,7.4,4.1,13.3c1.2,4.1,2.3,8.1,3.5,12.2
              C521.1,1443.9,523.9,1465.8,521.9,1478.9z"/>
            <path className="st4" d="M1619.7,1524.1c8.7,0,13.6,5.2,14.6,15.6h20.7c-0.7-10.1-3.6-18.3-8.8-24.6c-6.1-7.7-14.8-11.5-25.8-11.5
              c-12.4,0-21.7,3.5-27.9,10.5c-5.2,6-7.8,14-7.8,24c0,17.1,9.6,35.3,28.9,54.6c6.6,6.8,11.3,12.8,14.2,17.8
              c3.6,6.6,5.4,14,5.3,21.5c0,11.5-4.7,17.2-13.9,17.2c-4,0-7.2-1.8-9.8-5.3c-2.6-3.6-3.9-8.1-3.9-13.5h-21.9
              c0.3,11.6,3.3,21.1,9.2,28.3c6,7.7,14.2,11.5,24.4,11.5c12.4,0,22.1-3.8,29.1-11.5c6.2-6.8,9.2-15.2,9.2-25
              c0-10-1.6-18.6-4.7-25.8c-4.2-9.4-12.3-20.3-24.2-32.6c-6.4-6.5-10.9-11.8-13.5-15.8l0,0c-4.9-7.1-7.4-14.1-7.4-20.9
              C1605.5,1528.9,1610.2,1524.1,1619.7,1524.1z"/>
            <path className="st4" d="M2008.5,1440.2l40.8-215c-10.6-2-21.1-4-31.7-5.9c-13,22.5-25.9,45-38.5,67.6c-4.5-25.6-9.2-51.1-14.1-76.7
              c-10.5-1.7-21-3.4-31.5-5c-11.1,72.1-22.2,144.2-33.3,216.2c9.5,1.6,19.1,3.3,28.6,5.1c7.1-43.4,14.2-86.9,21.3-130.3
              c4.7,27.9,9.1,55.8,13.3,83.7c13.2-24.9,26.6-49.7,40.2-74.5l-23.3,129.4C1989.6,1436.7,1999,1438.4,2008.5,1440.2z"/>
            <path className="st4" d="M1813.8,1070.6c19.4,18.7,43.4,30,71.4,33.4c90,10.9,181.9,24.3,273.2,40l28.3,4.9l-0.8-122.5l-20.2-3.2
              c-29.7-4.7-60.1-9.3-90.6-13.7c-31.7-4.5-34.4-18.2-34.5-30.7c-1.8-260.1,0.1-808.2,0.1-808.2h-258.1l-0.1,832.2
              C1782.8,1028.1,1793.2,1051,1813.8,1070.6z"/>
            <path className="st4" d="M1333.1,1167c-33.5,0.8-66.9,2.2-100.4,4c0.5,8.5,0.9,16.9,1.4,25.4c11.9-0.7,23.8-1.2,35.7-1.8
              c2.9,64.4,5.7,128.8,8.6,193.2c9.9-0.4,19.8-0.8,29.7-1.2h0c-2.2-64.4-4.5-128.8-6.8-193.2c10.8-0.4,21.6-0.7,32.4-1
              C1333.5,1184,1333.3,1175.5,1333.1,1167z"/>
            <path className="st4" d="M795.1,1081.9c16.6,12,36.3,18,58.6,18c5.2,0,10.3-0.3,15.5-0.9c79.4-9.1,158.7-16.6,235.9-22.2l22.3-1.6
              l0.5-115.3l-25.9,1.8c-19.3,1.3-35.4,2.5-52.1,3.8c-2.6,0.2-5,0.3-7.3,0.3c-9,0-16-1.7-18.6-4.3c-2.7-2.7-3-9-3-12.5
              c0.8-154.3,1.7-308.6,2.6-462.9h59.3l0.6-122.2H1024V170.6l-262.2,0l0.1,846.2C761.7,1043.6,773.2,1066.1,795.1,1081.9z"/>
            <path className="st4" d="M1722.9,1347.8c-1.2,5.6-3.1,11.1-5.8,16.2c-3.1,5.6-9.2,7.9-18.1,7.1c-8-0.7-13.3-4.2-16-10.7
              c-3.8-10.5-5.7-32.2-5.6-64.9c0.1-2.9,0.3-7.1,0.7-12.6l1.5-11.4c4.5-29.3,8.9-48.5,13.4-57.6c3.6-7.4,10.4-10.7,20.4-9.8
              c7.8,0.7,13.2,5.1,15.9,13.3c1.2,4.5,2,7.8,2.4,9.8c10.8,1,21.6,2.1,32.4,3.2c-0.6-8.3-2.1-16.4-4.6-24.4
              c-6.3-17-20.9-26.6-43.4-28.6c-23.1-2-39.7,4.4-49.7,19c-6.9,10.7-12.4,32.8-16.2,66.6c-0.5,4.7-1.2,11.1-2,19.3
              c-0.4,5.7-0.6,13.3-0.7,23c1.1,35.5,3.2,57.4,6.6,65.7c7.4,16.7,21.6,25.7,42.8,27.6c21.6,1.9,36.9-4.5,46.2-19.5l0-0.1
              c4.2-7.1,7.8-16.5,10.5-28.3C1743.3,1349.8,1733.1,1348.7,1722.9,1347.8z"/>
            <path className="st4" d="M1815.4,1630.6c-0.5,4.3-1.5,8.5-3.1,12.5c-1.9,4.4-6.2,6.6-12.7,6.6c-5.9,0-10-2.3-12.3-7
              c-3.4-7.7-6-23.7-7.8-48.2c-0.1-2.2-0.2-5.3-0.2-9.4l0.4-8.6c1.5-22.1,3.6-36.8,6.2-43.9c2.1-5.7,6.6-8.6,13.5-8.6
              c5.5,0,9.4,3,11.9,8.8c1.1,3.3,1.8,5.7,2.2,7.2h22.8c-1-6.1-2.7-12.1-4.9-17.8c-5.6-12.2-16.2-18.3-31.8-18.3
              c-16,0-27.1,5.8-33.2,17.2c-4.2,8.3-6.9,25.2-8,50.7c-0.1,3.6-0.3,8.4-0.4,14.6c0,4.2,0.2,10,0.6,17.2c2.6,26.5,5.3,42.8,8.2,48.8
              c6.3,12,17.3,18,33,18c16,0,26.8-6,32.4-17.8l0-0.1c2.6-5.6,4.4-12.9,5.5-21.8H1815.4z"/>
            <path className="st4" d="M1704.6,1660.8c-1-7.1-4.6-10.7-10.9-10.7c-2.8-0.1-5.6,1-7.6,3.1c-2,2-3.1,4.7-3.1,7.6
              c-0.1,2.8,1.1,5.6,3.1,7.6c2,2,4.7,3.1,7.6,3.1l-1.8,3.9c2.3,2.9,4.4,5.7,6.3,3.9c3.5-3.3,5.7-7.7,6.4-12.5l0-0.1
              C1704.9,1664.8,1704.9,1662.8,1704.6,1660.8z"/>
            <path className="st4" d="M2596.5,1138.6c-29.5-7.5-59.4-15-89-22.2c-25.3-6.1-35.6-18.1-35.7-41.4V170.6h-255.4l-0.1,914
              c0.2,26.2,10,50.8,29.3,73.2c18.9,21.8,42.3,36,69.8,42.3c57.6,13,299.2,78.2,299.2,78.2l0.1-135L2596.5,1138.6z"/>
            <polygon className="st4" points="1141.5,1866.4 1141.5,1817.6 1161.3,1817.6 1161.3,1801.9 1141.5,1801.9 1141.5,1763 1175.8,1763 
              1175.8,1747.4 1124.1,1747.4 1124.1,1882.1 1177.5,1882.1 1177.5,1866.4 		"/>
            <path className="st4" d="M2423.7,1319.2c-9.8-3-19.7-6-29.6-9l-35.6,118.1c-1.7-43.4-3.8-86.9-6.5-130.4c-9.9-2.8-19.8-5.6-29.8-8.4
              c-19.4,70.1-38.8,140.2-58.3,210.3c9.3,2.6,18.6,5.2,27.9,7.9c11.9-41.5,23.8-83.1,35.8-124.6c1.8,45.4,2.9,90.8,3.6,136.1
              c9.2,2.8,18.4,5.6,27.7,8.5C2380.5,1458.2,2402.1,1388.7,2423.7,1319.2z"/>
            <path className="st4" d="M2331.2,1519.2C2331.2,1519.2,2331.2,1519.2,2331.2,1519.2C2331.2,1519.1,2331.2,1519.1,2331.2,1519.2
              L2331.2,1519.2z"/>
            <path className="st4" d="M2219.1,1702.6c-22.6,24.3-37.6,58.4-52.8,71.5c-20.8,17.8-71.4,1.2-78.2-14.1c-1.2-2.8-3.8-7.9,0.2-13.6
              c27.4-38.7,116.8-124,116.8-124c2.5-2.3,4.8,9.9,6.3,20.8c10.3-23.6,19.1-47.8,26.5-72.4l0.4-0.4l-0.3,0.1l0.1-0.3l-0.4,0.4
              c-24.4,8.2-48.3,17.8-71.5,28.8c11,1.1,23.2,3.1,21,5.6c0,0-82.3,92.1-120,120.8c-5.6,4.2-10.8,1.8-13.6,0.6
              c-15.5-6.3-33.8-56.4-16.7-77.8c12.6-15.7,46.2-31.8,69.8-55.2c21.7-21.5,25-52.8,26.2-69.4l-48.2,44.8c0,0,26.5-10,26.4-1.4
              c-19.3,29.9-82.7,46.1-99.7,80.1c-13.1,26.2,5.4,73,13.6,88.4c4.8,9,4.6,19.8-0.6,28.5l-2.9,3l-2.5-2.4c0,0,0,0,0,0
              c-4-3.8-10.3-3.7-14.1,0.3c0,0,0,0,0,0c-3.8,4-3.7,10.3,0.3,14.1l2.5,2.4l-0.9,1l-4.2-4c-4.4-4.2-12.1-3.4-17.2,1.8
              s-5.6,12.9-1.2,17.2l4.2,4l-107.2,108.2h54.1l79.8-82.4l4.1,4c4.4,4.2,12.1,3.4,17.2-1.8s5.6-12.9,1.2-17.2l-4.1-4l0.9-1l2.4,2.3
              c0,0,0,0,0,0c4,3.8,10.3,3.7,14.1-0.3c0,0,0,0,0,0c3.8-4,3.7-10.3-0.3-14.1l-2.4-2.4l2.9-3c8.6-5.5,19.4-6.1,28.5-1.5
              c15.6,7.7,63,24.6,88.8,10.6c33.4-18.2,47.5-82,76.8-102.2c8.6-0.4-0.6,26.4-0.6,26.4l43.2-49.5
              C2271,1675.8,2239.9,1680.2,2219.1,1702.6z"/>
            <path className="st4" d="M2533.7,1355c-19.2,22.4-38.1,44.8-56.8,67.5c-1-29.3-2.2-58.6-3.6-87.9c-10.5-3.4-21-6.8-31.6-10.2
              c3.3,43.8,6,87.5,8.1,131.2c-10.2,30.5-20.3,61.1-30.5,91.6c9.4,3.1,18.9,6.3,28.3,9.6l31.6-91.8l0-0.1
              c28.2-33.2,56.9-66.1,86.2-98.6C2554.7,1362.4,2544.2,1358.6,2533.7,1355z"/>
            <path className="st4" d="M1348.2,1869.1c-1.6-1.7-3.9-2.6-6.2-2.5c-2.3,0-4.6,0.9-6.2,2.5c-1.7,1.7-2.6,3.9-2.5,6.2
              c0,2.3,0.9,4.6,2.5,6.2c1.6,1.7,3.9,2.6,6.2,2.5c2.3,0.1,4.6-0.9,6.2-2.5l0,0c1.7-1.6,2.6-3.9,2.6-6.2
              C1350.8,1873,1349.9,1870.7,1348.2,1869.1z"/>
            <path className="st4" d="M1245.5,1853.1c0-8.2-1.3-15.3-3.9-21.2c-3.5-7.8-10.1-16.7-19.9-26.8c-5.3-5.4-9-9.8-11.1-13
              c-4.1-5.8-6.1-11.6-6.1-17.2c0-7.8,3.9-11.8,11.6-11.8c7.2,0,11.2,4.3,12,12.8h17c-0.6-8.3-3-15-7.2-20.2
              c-5-6.3-12.1-9.4-21.2-9.4c-10.2,0-17.9,2.8-22.9,8.6c-4.3,4.9-6.4,11.5-6.4,19.7c0,14,7.9,29,23.7,44.8
              c5.4,5.7,9.2,10.5,11.6,14.7c3,5.4,4.5,11.5,4.4,17.6c0,9.4-3.8,14.2-11.5,14.2c-3.2,0-6.2-1.7-8-4.4c-2.2-2.9-3.2-6.6-3.2-11.1
              h-18c0.2,9.5,2.8,17.3,7.6,23.2c4.9,6.3,11.6,9.4,20.1,9.4c10.2,0,18.2-3.1,23.9-9.4l0,0C1242.9,1868,1245.6,1860.7,1245.5,1853.1
              z"/>
            <polygon className="st4" points="1199.1,1504.9 1178,1504.9 1178,1669 1246.3,1669 1246.3,1650.1 1199.1,1650.1 		"/>
            <polygon className="st4" points="1255.3,1763 1275.7,1763 1275.7,1882.1 1293.7,1882.1 1293.7,1763.1 1312.2,1763.1 1312.2,1747.4 
              1255.3,1747.4 		"/>
            <polygon className="st4" points="1450.1,1669 1450.1,1505.3 1429,1505.3 1429,1597.8 1399.1,1505.3 1377.9,1505.3 1377.9,1669 
              1399.1,1669 1399.1,1571.8 1429,1669 		"/>
            <path className="st4" d="M1451.3,1821.4c6.7-8.8,11.4-15.8,14.2-20.8c4-7.4,6.1-15.7,6.2-24.1c0-8.8-2.5-16.2-7.4-22
              c-5.4-6.2-12.6-9.2-21.7-9.1c-9.9,0.1-17.5,3.8-22.9,11.3c-4.6,6.5-6.9,14.6-6.9,24.2h17.3c-0.2-4,0.6-7.9,2.4-11.4
              c1.9-3.8,5.9-6.1,10.1-5.9c3.2-0.1,6.2,1.3,8.1,3.8c2.2,2.9,3.3,6.4,3.1,10c0,6.3-2.3,13.1-6.9,20.3c-2.6,4.2-8.3,12-17.2,23.6
              c-6.8,8.8-11.7,16.5-14.5,22.9c-4.2,9.5-6.3,19.8-6.2,30.1v7.6h60.4v-15.7l-40.4-0.1c0.9-8.5,3.6-16.7,7.9-24.1
              C1439.8,1837.2,1444.5,1830.3,1451.3,1821.4z"/>
            <path className="st4" d="M1450.2,1384.9c0.1-8.5,0.2-17,0.2-25.5c-20-0.2-40-0.2-60,0c-0.2-26.4-0.5-52.8-0.7-79.2
              c11.3-0.1,22.6-0.1,33.8-0.1c0-8.5,0-17,0-25.5c-11.4,0-22.7,0-34.1,0.1c-0.2-21.1-0.4-42.1-0.6-63.1c20.1-0.2,40.1-0.2,60.2,0
              c0.1-8.5,0.2-16.9,0.2-25.4c-30.4-0.3-60.9-0.2-91.3,0.4l0,0c1.3,72.9,2.6,145.8,4,218.8
              C1391.4,1384.7,1420.8,1384.6,1450.2,1384.9z"/>
          </g>
          <path className="st4" d="M1746.6,961.6l-22.5-1.4c-77.6-5-232.8-10.4-232.8-10.4l-54.3-194.9l308.6,9.3l-1.3-299.5
            c-0.1-19.4-7.2-47.6-40.9-71.8c-26.1-18.8-54.1-28.6-83.3-29h-406c-31.4,0.2-57.9,9-78.9,26c-22.2,18-34,41.8-34.1,68.9
            c0,8.6,1.5,17.2,4.3,25.4c41.1,148.1,85.3,305.7,143.3,511.1c11.4,43.8,41.6,67.9,85.1,67.9c129,0,259.3,4.5,387.4,13.3l25.9,1.8
            L1746.6,961.6z M1362.7,477.7h195.6l8.4,163l-160.8-0.3L1362.7,477.7z"/>
          <path className="st4" d="M1167.1,1363.1c6.3-14.4,9-28.6,8-42.6l-0.4-5.5c-9.1,0.7-18.2,1.4-27.2,2.2c0.1,1.4,0.2,2.7,0.4,4.1
            c0.3,4.2-0.3,8.5-1.6,12.5c-10.6-15.9-21.9-31.9-33.8-48c-9.9-13.2-17.4-23.1-22.4-29.8c-7.2-9.5-11.2-18.2-12-26
            c-1.3-12.9,2.9-19.8,12.7-20.8c8.5-0.8,13.6,4.4,15.5,15.5c9.5-0.9,19.1-1.7,28.6-2.6c-1.1-13.4-5.3-23.8-12.7-31.2
            c-8.2-8-19.5-11.4-33.7-10c-14.5,1.4-25.4,7.2-32.6,17.1c-6.6,9.3-9,20.9-7.5,34.6c1.4,12,6,23.4,13.3,33
            c7.5,10.2,12.6,16.9,15.3,20.3c-10.6,9.9-17.9,19.2-22.2,27.7c-5.1,10.6-7.1,22.5-5.5,34.2h0c2,16.8,8.2,30.5,18.6,40.9
            c11.4,11.8,25,16.9,41.1,15.3c18.8-2,32.2-8,40.6-18c2,3.1,4.1,6.3,6.1,9.4c10.6-0.9,21.3-1.7,31.9-2.5
            C1180.7,1383.1,1173.9,1373.1,1167.1,1363.1z M1121.4,1370.6c-2.8,1.3-7,2.3-13,2.9c-6.9,0.7-13.2-2.1-19-8.3
            c-5.8-6.2-9.3-14.3-10.4-24.5c-1.1-10.2,3.8-20.6,14.6-31.2c12.8,17.5,25.4,35.1,37.9,52.7
            C1127.4,1366.6,1124.2,1369.3,1121.4,1370.6z"/>
          <path className="st4" d="M1891.3,1222c-5.4-17-19.8-27.2-43.3-30.2c-11.1-1.4-21-0.3-29.6,3s-15.2,8.4-19.5,15.3
            c-9.7,16.3-16.4,43.4-20.6,81.1c-0.9,8.5-1.6,18.2-2.1,29.2l0,0c-1.2,31.8,0,52.4,3.6,62c6,17,19.2,26.7,40,29.3
            c20.4,2.6,35.9-3.2,46.7-17.9c4.5-6.9,8.8-17.2,12.7-30.8c0-0.2,2.5-10.9,7.5-32.1c1.1-4.9,2.1-10.6,3.1-17.5
            C1896.2,1270.8,1896.7,1240.3,1891.3,1222z M1862.6,1288.7c-0.2,3.1-0.8,7.7-1.6,13.9c-0.6,4.2-1.1,8.3-1.7,12.5
            c-5.2,31.3-11.5,52.6-18.6,63.7c-3.6,5.4-9.2,7.6-17,6.3c-7.2-0.9-12-4.3-14.3-10.3c-4.7-13-5.4-35.1-2.3-66.3
            c0.2-3.1,0.6-7.1,1.2-12c0.8-6.3,1.4-11.1,2-14.4c5-27.5,10.6-46.1,16.6-56.1c4-5.8,10.1-8.2,18.1-7.1c8,1,13.2,5,15.7,11.7
            C1864.1,1240.8,1864.7,1260.1,1862.6,1288.7z"/>
          <path className="st4" d="M2165.2,1256.9c-7.9-7-17.5-11.7-27.8-13.8c-17.7-3.9-35.5-7.6-53.2-11.1c-14.3,71.5-28.7,143-43.1,214.5
            c9.4,1.9,18.8,3.8,28.2,5.8l21-99.8c8.2,1.7,16.3,3.5,24.5,5.2c9.1,2.1,18.5,2.1,27.5,0c5.7-1.3,11.8-5.8,18.3-13.5
            c7.4-9,12.7-19.6,15.3-31C2181.6,1289.1,2178.1,1270.2,2165.2,1256.9z M2144.5,1304.6c-1.4,6-3.1,11.1-5.2,15.1
            c-4.4,8.6-10.6,12.8-18.4,12.8c-1.6,0-3.4-0.2-5.2-0.6c-6.7-1.4-13.4-2.9-20.2-4.3c2.3-10.8,4.5-21.5,6.8-32.3
            c2.3-10.8,4.5-21.5,6.8-32.3l0,0l0,0c6.8,1.4,13.7,2.9,20.6,4.4C2144.4,1270.7,2149.4,1283.1,2144.5,1304.6z"/>
          <path className="st4" d="M2226.5,1263.8c-33.9,66.8-66.7,134-98.4,201.5c9.6,2.2,19.3,4.5,28.9,6.8c9.2-18.3,18.6-36.6,28-54.8
            c10.5,2.5,21,5.2,31.5,7.9c-0.5,20.5-1.1,41-1.8,61.4c9.5,2.5,18.9,5,28.4,7.5c4-74.5,7-149.1,8.9-223.8
            C2243.6,1268,2235.1,1265.9,2226.5,1263.8z M2221.3,1376.9c-6.3-1.6-12.5-3.2-18.8-4.7c7-15.7,14.1-31.3,21.2-46.9
            C2222.9,1342.5,2222.1,1359.7,2221.3,1376.9z"/>
          <path className="st4" d="M939.2,1587.9c0.9-0.6,1.8-1.2,2.7-1.8c4.1-2.5,7.6-6.6,10.5-12.3c3.8-7.4,5.7-15.7,5.5-24
            c0-18.6-5.3-31.7-16-39.4c-6.3-3.7-13.4-5.6-20.7-5.5h-36.1v164h21.3l-0.2-76.5h12.7l18.2,76.9l21.3,0L939.2,1587.9z M919.5,1573.4
            h-13.3V1524h13.3c10.4,0,15.6,8.3,15.6,24.8C935.1,1565.2,929.9,1573.4,919.5,1573.4z"/>
          <path className="st4" d="M1150.5,1570.4c-0.2-8.8-1-17.6-2.5-26.2c-1.5-9.6-3.2-16.9-5.1-22c-5.3-11.5-16.4-17.2-33.2-17.2h-31.8V1669
            l31,0c16.8,0,28-5.6,33.6-16.8c4.1-8.9,6.8-25.4,8-49.6c0.3-7.8,0.4-13.4,0.4-16.8C1150.9,1581.4,1150.8,1576.2,1150.5,1570.4z
            M1128.7,1601.9c-1.2,14.6-1.9,22.3-2.1,23c-1.1,7.7-2.7,13.8-4.7,18.5c-1.9,3-3.8,4.9-5.6,5.5c-1.9,0.7-5.1,1-9.7,1h-7.6V1524h7.6
            c8.8,0,14.1,2.9,16,8.6c3.1,9.4,5.3,22.7,6.4,39.8c0.3,3.4,0.4,8.1,0.4,14C1129.4,1591.6,1129.2,1596.8,1128.7,1601.9z"/>
          <path className="st4" d="M1560,1585.7c0-4.4-0.1-9.5-0.4-15.4c-0.2-8.8-1-17.6-2.5-26.2c-1.5-9.6-3.2-16.9-5.1-22
            c-5.3-11.5-16.4-17.2-33.2-17.2H1487V1669h31c16.8,0,28-5.6,33.6-16.8c4.1-8.9,6.8-25.4,8-49.6l0-0.1
            C1559.9,1594.7,1560,1589.1,1560,1585.7z M1537.9,1601.9c-1.2,14.6-1.9,22.3-2.1,23c-1.1,7.7-2.7,13.8-4.7,18.5
            c-1.9,3-3.8,4.9-5.6,5.5c-1.8,0.7-5.1,1-9.7,1h-7.6V1524h7.6c8.8,0,14.1,2.9,16,8.6c3.1,9.4,5.3,22.7,6.4,39.8
            c0.3,3.4,0.4,8.1,0.4,14C1538.6,1591.6,1538.4,1596.8,1537.9,1601.9z"/>
          <path className="st4" d="M1315.3,1504.9h-18l-34.9,164.1h21.7l10.5-44.9h23.4l9.8,44.9h21.5L1315.3,1504.9z M1299.8,1601.5l6.6-37.9
            l7.2,37.9H1299.8z"/>
          <path className="st4" d="M1542.9,1168.2c-8.7-0.3-17.5-0.6-26.2-0.9c-19,72.5-36.8,145-53.6,217.7c9.8,0.1,19.7,0.3,29.5,0.6
            c5.2-19.9,10.5-39.7,15.9-59.5c10.7,0.3,21.5,0.7,32.2,1.1c3.8,20.1,7.5,40.3,11.1,60.4c9.7,0.4,19.4,0.9,29.1,1.4h0
            C1569.3,1315.3,1556.7,1241.7,1542.9,1168.2z M1515.8,1284.1c3.6-16.8,7.2-33.6,10.8-50.4c2.9,17,5.7,34,8.4,51
            C1528.6,1284.5,1522.2,1284.3,1515.8,1284.1z"/>
          <path className="st4" d="M1905.4,1505h-18l-34.9,164.1h21.7l10.5-44.9h23.3l9.8,44.9h21.5L1905.4,1505z M1889.8,1601.5l6.6-37.9
            l7.2,37.9H1889.8z"/>
          <path className="st4" d="M1546.9,1814.1c-0.1-8.3-0.2-12.9-0.2-13.6c-0.6-11.7-1.6-20.4-3-26.2c-1.8-7.9-5.4-15.2-10.4-21.6
            c-3.4-3.5-8.4-5.7-15-6.6c-1.4-0.1-2.4-0.2-3.1-0.2c-0.9,0-1.9,0.1-3,0.2c-5.5,0.5-10.7,2.9-14.6,6.7c-4.8,5-8.3,11.7-10.3,20.4
            c-1.6,6.5-2.6,15.6-3.2,27.2c-0.2,4.1-0.2,8.6-0.2,13.6c0,5.2,0.2,10.6,0.7,16.3c0.9,12.4,1.8,20.9,2.7,25.4
            c1.9,8.9,5.3,15.7,10.1,20.5c3.7,3.8,8.5,6.3,13.8,6.9c1.3,0.2,2.7,0.3,4,0.2c1.4,0.1,2.7,0,4.1-0.2c3.8-0.3,8.2-2.4,13-6.2
            c5-4.8,8.6-12.1,10.7-21.9c1.2-5,2.4-14,3.5-26.9l-0.1-0.1C1547,1823.5,1547.1,1818.8,1546.9,1814.1z M1529,1820.7
            c-0.2,6.1-0.4,12.2-0.7,18.2c-0.4,8.1-1.3,14.2-2.9,18.2c-2,5.5-5.2,8.2-9.7,8.2c-4.4,0-7.7-2.7-10-8.2c-1.7-4.2-2.8-10.2-3.4-18.2
            c-0.3-6-0.7-12.1-1-18.2c-0.1-1.5-0.2-4.1-0.2-7.7c0-2,0.1-4.9,0.3-8.6c0.2-5.7,0.5-11.4,0.7-17.2c0.4-7.2,1.4-12.8,3-16.4
            c2.1-4.9,5.4-7.4,9.9-7.4c4.4,0,7.7,2.5,9.8,7.4c1.5,3.7,2.7,9.2,3.2,16.4c0.2,5.7,0.5,11.4,0.7,17.2c0.3,3.7,0.4,6.6,0.4,8.6
            C1529.2,1816.6,1529.2,1819.2,1529,1820.7z"/>
          <path className="st4" d="M1623.3,1814.1c-0.1-8.3-0.1-12.8-0.1-13.6c-0.6-11.7-1.6-20.4-3.1-26.2c-1.8-7.9-5.4-15.3-10.4-21.6
            c-3.4-3.5-8.3-5.7-15-6.6c-1.3-0.1-2.3-0.2-3-0.2c-0.9,0-1.9,0.1-3,0.2c-5.5,0.5-10.7,2.9-14.7,6.7c-4.8,5-8.3,11.7-10.3,20.4
            c-1.5,6.5-2.6,15.6-3.2,27.2c-0.2,4.1-0.3,8.7-0.2,13.6c0,5.2,0.2,10.6,0.7,16.3c0.9,12.4,1.8,20.9,2.7,25.4
            c1.9,8.9,5.3,15.7,10.1,20.5c3.7,3.8,8.5,6.3,13.8,6.9c1.3,0.2,2.7,0.3,4,0.2c1.3,0.1,2.7,0,4-0.2c3.9-0.3,8.2-2.4,13-6.2
            c5.1-4.8,8.7-12.1,10.8-21.9c1.2-5,2.4-14,3.5-26.9l0-0.1C1623.3,1823.5,1623.4,1818.8,1623.3,1814.1z M1605.4,1820.7
            c-0.2,6.1-0.5,12.2-0.7,18.2c-0.3,8.1-1.3,14.2-2.9,18.2c-2,5.5-5.2,8.2-9.7,8.2c-4.5,0-7.8-2.7-10-8.2c-1.7-4.2-2.8-10.2-3.4-18.2
            c-0.3-6-0.6-12.1-1-18.2c-0.1-1.5-0.2-4.1-0.2-7.7c0-2,0.1-4.9,0.3-8.6c0.2-5.7,0.4-11.4,0.7-17.2c0.5-7.2,1.5-12.8,3.1-16.4
            c2.1-4.9,5.4-7.4,9.9-7.4c4.4,0,7.6,2.5,9.8,7.4c1.5,3.7,2.6,9.2,3.2,16.4c0.2,5.7,0.4,11.5,0.7,17.2c0.3,3.7,0.4,6.6,0.4,8.6
            C1605.6,1816.6,1605.5,1819.2,1605.4,1820.7z"/>
          <path className="st4" d="M1689.1,1828v-80.6h-18.6l-37.7,81v15.3h39v38.6h17.3v-38.6h9l0.1-15.7H1689.1z M1671.7,1828H1650l21.7-46.3
            V1828z"/>
          <g>
            <path className="st4" d="M1158,43.8v193.3h491.2V43.8h956.7l-0.5,977.5l143.2,36.4v370.7l-137.7-39.2l-131.5,267.8
              c-7.2-0.3-14.1-0.5-20.6-0.5c-103,0-122.4,36.7-151,67.7c-30.4,33-17.9,57.6-112.5,168.6c-79.5,88.4-224.3,97.6-281.4,97.6
              c-15.1,0-24.1-0.6-24.1-0.6s-869.1-0.9-969.2-0.9c-13.7,0.6-26.5,0.9-38.5,0.9c-95.8,0-139.4-17.6-139.4-17.6
              c-144.8-33.2-187.5-133.5-248.6-237c-41.1-69.5-105.4-80.9-143.2-80.9c-18.5,0-30.6,2.7-30.6,2.7l-90.6-276L82,1430.7l-3.4-403.5
              l231.6-49.6c0,0-245.8-474.9-259.8-590.4c-28.8-190.7,118-328,239.6-341C402,46.3,1158,43.8,1158,43.8 M1173,28.8l-15,0
              c-7.6,0-757.2,2.5-867.9,2.5h-0.8l-0.8,0.1c-33.4,3.6-68.5,15.8-101.6,35.5c-33.7,20.1-64.6,47.5-89.1,79.4
              c-25.3,32.8-43.7,69.3-54.6,108.6c-12,43.1-14.5,88.4-7.6,134.4c6.1,49.7,52.1,165,136.5,342.9c48.5,102.3,96.5,197,115.9,234.9
              l-212.5,45.5l-12,2.6l0.1,12.2l3.4,403.5l0.2,20.7l19.6-6.6l133.4-44.9l85.8,261.6l4.2,12.9l13.3-3c0.1,0,11-2.4,27.3-2.4
              c15.9,0,39.9,2.3,64.3,13.1c27.3,12.1,49.5,32.5,66,60.5c6.4,10.9,12.8,21.9,18.9,32.6c26.8,46.7,52.2,90.9,88.1,128.1
              c19.6,20.3,40.5,36.7,64,50c25.8,14.7,54,25.5,86.2,32.9c8.1,3,53.8,18.2,143.8,18.2c12.6,0,25.6-0.3,38.8-0.9
              c99.5,0,943.5,0.8,968.3,0.9c2.3,0.1,11.1,0.6,24.6,0.6c60.2,0,209.3-10,292.6-102.6l0.1-0.1l0.1-0.2
              c60.6-71.1,78.8-108.7,90.8-133.5c7-14.4,11.6-24,21.4-34.6c3.2-3.5,6.3-7.1,9.3-10.5c11.8-13.6,23-26.4,41.4-35.9
              c21.4-11.1,50.6-16.4,89.2-16.4c6.4,0,13.1,0.1,20,0.4l9.8,0.4l4.3-8.8l126-256.5l125.7,35.7l19.1,5.4v-19.9v-370.7v-11.7
              l-11.3-2.9l-131.9-33.5l0.5-965.8l0-15h-15h-956.7h-15v15v178.3H1173V43.8V28.8L1173,28.8z"/>
          </g>
        </g>
      </svg>

      <svg data-name="Layer 1" role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 546.91 400.46">
        <mask id="myMask">
          <path className="cls-1" d="M265.44,199.09V236.2l85.38-.18V198.9l195.5.19V388l27.91,7.53v84.14L546,470.76l-25.21,52.7s-16.37-2.05-22.79,2.42c0,0-11.58,6-21.63,28.46-8.23,12-14.79,21.21-30.14,27.35s-32.65,6.14-46.6,6.14h-210c-16.47,0-26.51-6.56-35.16-14.79-15-11.48-19.64-29.75-29.44-40.32-10.89-9.63-16.75-10.33-30.42-9.91-4.75-17.44-17.32-50.73-17.32-50.73l-30.51,9.11-1.3-90.42,40.31-9.17S56.31,327,41.3,282.15c-6.2-30-.59-58.22,27-75.75,0,0,11.83-8.29,41.69-8.15Z" transform="translate(-33 -194)" />
        </mask>
        <rect ref={progress} mask="url(#myMask)" />
        <rect ref={progress} mask="url(#myMask)" />
        <rect ref={progress} mask="url(#myMask)" />
        <rect ref={progress} mask="url(#myMask)" />
        <rect ref={progress} mask="url(#myMask)" />
      </svg>
    </div>
  );
}

export default Preloader;